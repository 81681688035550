import { Pipe, PipeTransform } from "@angular/core";

const PADDING = "000000";

@Pipe({ name: "timecolonpipe" })
export class TimeColonPipe {

  private DECIMAL_SEPARATOR: string;
  private THOUSANDS_SEPARATOR: string;

  constructor() {
    // TODO comes from configuration settings
    this.DECIMAL_SEPARATOR = ".";
    this.THOUSANDS_SEPARATOR = ",";
  }

  parse(value: string, fractionSize: number = 2): string {
    if(isNaN(Number(value.substr(0,2))) || isNaN(Number(value.substr(3,5))) && value.length < 5){
        return "";
    }else{
        if(Number(value.substr(0,2)) > 23 || Number(value.substr(3,2)) > 59){
            return "";
        }else if(value.length == 2){
            return value+":";
        }else if(value.length > 5){
            if(value.substr(3,1) == ":"){
                return "";
            }else{
                return value.substr(0,5);
            }
        }else if(isNaN(Number(value.substr(0,2))) || isNaN(Number(value.substr(3,5)))){
            return "";
        }else{
            return value;
        }
    }
  }

}