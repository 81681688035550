import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { ConfigUrl } from "../services/config-url";
import { map } from "rxjs/operators";
import { PoliceStation } from "../models/police-station.model";
import { Title } from "../models/title.model";
import { Accuse } from "../models/accuse.model";
import { CarBrand } from "../models/car-brand.model";
import { CarColor } from "../models/car-color.model";
import { CarType } from "../models/car-type.model";
import { Location } from "../models/location.model";
import { Reason } from "../models/reason.model";
import { CameraInfo } from "../models/camera-info.model";
import { ProvinceTransport } from "../models/province-transport.model";
import { Tambon } from "../models/tambon.model";
import { Permission } from "../models/permission.model";
import { AmphurTransport } from "../models/amphur-transport.model";
import { Amphur } from "../models/amphur.model";
import { TicketTransportStatus } from "../models/ticketTransportStatus.model";
import { StorageMap } from "@ngx-pwa/local-storage";
import { AccuseCarCategory } from "../models/accuse-car-category.model";
import { TambonItraffic } from "../models/tambon-itraffic.model";
import { LocationDetail } from "../models/location-detail.model";

@Injectable()
export class InitDataProvider {

    private _camera: CameraInfo[] = null;
    private _policeStation: PoliceStation[] = null;
    private _title: Title[] = null;
    private _accuse: Accuse[] = null;
    private _carBrand: CarBrand[] = null;
    private _carColor: CarColor[] = null;
    private _carType: CarType[] = null;
    private _location: Location[] = null;
    private _location_detail: LocationDetail[] = null;
    private _reason: Reason[] = null;
    private _province: ProvinceTransport[] = null;
    private _amphur: Amphur[] = null;
    private _amphurTransport: AmphurTransport[] = null;
    private _tambon: Tambon[] = null;
    private _tambon_itraffic: TambonItraffic[] = null;
    private _permission: Permission[] = null;
    private _accuseCarCategory: AccuseCarCategory[] = null;
    private _ticketTransportStatus: TicketTransportStatus[] = null;

    constructor(private http: HttpClient,
        private storage: StorageMap, ) {

    }

    public load() {
        return new Promise((resolve, reject) => {
            this.http.get<any>(ConfigUrl.host + '/init/data').subscribe(
                (res) => {
                    this.camera = res['camera'];
                    this.policeStation = res['police_station'];
                    this.location = res['location'];
                    this.location_detail = res['location_detail'];
                    resolve(true);
                })
        })
    }

    public loadPermanent() {
        return new Promise((resolve, reject) => {
            this.storage.get('initdata').subscribe(
                (data: any) => {
                    if (data != null) {
                        this.title = data['title'];
                        this.accuse = data['accuse'];
                        this.carBrand = data['car_brand'];
                        this.carColor = data['car_color'];
                        this.carType = data['car_type'];
                        this.reason = data['reason'];
                        this.province = data['province'];
                        this.amphur = data['amphur'];
                        this.amphurTransport = data['amphur_transport'];
                        this.tambon = data['tambon'];
                        this.tambon_itraffic = data['tambon_itraffic'];
                        this.permission = data['permission'];
                        this.accuseCarCategory = data['accuse_car_category'];
                        this.ticketTransportStatus = data['ticket_transport_status'];
                        resolve(true);
                    } else {
                        this.http.get<any>(ConfigUrl.host + '/init/dataperm').subscribe(
                            (res) => {
                                this.title = res['title'];
                                this.accuse = res['accuse'];
                                this.carBrand = res['car_brand'];
                                this.carColor = res['car_color'];
                                this.carType = res['car_type'];
                                this.reason = res['reason'];
                                this.province = res['province'];
                                this.amphur = res['amphur'];
                                this.amphurTransport = res['amphur_transport'];
                                this.tambon = res['tambon'];
                                this.tambon_itraffic = res['tambon_itraffic'];
                                this.permission = res['permission'];
                                this.accuseCarCategory = res['accuse_car_category'];
                                this.ticketTransportStatus = res['ticket_transport_status'];
                                this.storage.set('initdata', res).subscribe(
                                    () => {
                                        //console.log("set!!");
                                        resolve(true);
                                    }
                                );
                            })
                    }
                }
            )
        })
    }

    /**
     * Getter camera
     * @return {CameraInfo[] }
     */
    public get camera(): CameraInfo[] {
        return this._camera;
    }

    /**
     * Getter policeStation
     * @return {PoliceStation[] }
     */
    public get policeStation(): PoliceStation[] {
        return this._policeStation;
    }

    /**
     * Getter title
     * @return {Title[] }
     */
    public get title(): Title[] {
        return this._title;
    }

    /**
     * Getter accuse
     * @return {Accuse[] }
     */
    public get accuse(): Accuse[] {
        return this._accuse;
    }

    /**
     * Getter carBrand
     * @return {CarBrand[] }
     */
    public get carBrand(): CarBrand[] {
        return this._carBrand;
    }

    /**
     * Getter carColor
     * @return {CarColor[] }
     */
    public get carColor(): CarColor[] {
        return this._carColor;
    }

    /**
     * Getter carType
     * @return {CarType[] }
     */
    public get carType(): CarType[] {
        return this._carType;
    }

    /**
     * Getter location
     * @return {Location[] }
     */
    public get location(): Location[] {
        return this._location;
    }

    /**
     * Getter location_detail
     * @return {LocationDetail[] }
     */
     public get location_detail(): LocationDetail[] {
        return this._location_detail;
    }

    /**
     * Getter reason
     * @return {Reason[] }
     */
    public get reason(): Reason[] {
        return this._reason;
    }

    /**
     * Getter province
     * @return {ProvinceTransport[] }
     */
    public get province(): ProvinceTransport[] {
        return this._province;
    }

    /**
     * Getter amphur
     * @return {Amphur[] }
     */
    public get amphur(): Amphur[] {
        return this._amphur;
    }

    /**
     * Getter amphurTransport
     * @return {AmphurTransport[] }
     */
    public get amphurTransport(): AmphurTransport[] {
        return this._amphurTransport;
    }

    /**
     * Getter tambon
     * @return {Tambon[] }
     */
    public get tambon(): Tambon[] {
        return this._tambon;
    }

    /**
     * Getter permission
     * @return {Permission[] }
     */
    public get permission(): Permission[] {
        return this._permission;
    }

    /**
     * Getter ticket_transport_status
     * @return {TicketTransportStatus[] }
     */
     public get ticketTransportStatus(): TicketTransportStatus[] {
        return this._ticketTransportStatus;
    }

    /**
     * Setter camera
     * @param {CameraInfo[] } value
     */
    public set camera(value: CameraInfo[]) {
        this._camera = value;
    }

    /**
     * Setter policeStation
     * @param {PoliceStation[] } value
     */
    public set policeStation(value: PoliceStation[]) {
        this._policeStation = value;
    }

    /**
     * Setter title
     * @param {Title[] } value
     */
    public set title(value: Title[]) {
        this._title = value;
    }

    /**
     * Setter accuse
     * @param {Accuse[] } value
     */
    public set accuse(value: Accuse[]) {
        this._accuse = value;
    }

    /**
     * Setter carBrand
     * @param {CarBrand[] } value
     */
    public set carBrand(value: CarBrand[]) {
        this._carBrand = value;
    }

    /**
     * Setter carColor
     * @param {CarColor[] } value
     */
    public set carColor(value: CarColor[]) {
        this._carColor = value;
    }

    /**
     * Setter carType
     * @param {CarType[] } value
     */
    public set carType(value: CarType[]) {
        this._carType = value;
    }

    /**
     * Setter location
     * @param {Location[] } value
     */
    public set location(value: Location[]) {
        this._location = value;
    }

    /**
     * Setter location_detail
     * @param {LocationDetail[] } value
     */
    public set location_detail(value: LocationDetail[]) {
        this._location_detail = value;
    }

    /**
     * Setter reason
     * @param {Reason[] } value
     */
    public set reason(value: Reason[]) {
        this._reason = value;
    }

    /**
     * Setter province
     * @param {ProvinceTransport[] } value
     */
    public set province(value: ProvinceTransport[]) {
        this._province = value;
    }

    /**
     * Setter amphur
     * @param {Amphur[] } value
     */
    public set amphur(value: Amphur[]) {
        this._amphur = value;
    }

    /**
     * Setter amphurTransport
     * @param {AmphurTransport[] } value
     */
    public set amphurTransport(value: AmphurTransport[]) {
        this._amphurTransport = value;
    }

    /**
     * Setter tambon
     * @param {Tambon[] } value
     */
    public set tambon(value: Tambon[]) {
        this._tambon = value;
    }

    /**
     * Setter permission
     * @param {Permission[] } value
     */
    public set permission(value: Permission[]) {
        this._permission = value;
    }

    /**
     * Getter accuseCarCategory
     * @return {AccuseCarCategory[] }
     */
	public get accuseCarCategory(): AccuseCarCategory[]  {
		return this._accuseCarCategory;
	}

    /**
     * Setter accuseCarCategory
     * @param {AccuseCarCategory[] } value
     */
	public set accuseCarCategory(value: AccuseCarCategory[] ) {
		this._accuseCarCategory = value;
	}

    /**
     * Getter tambon_itraffic
     * @return {TambonItraffic[] }
     */
	public get tambon_itraffic(): TambonItraffic[]  {
		return this._tambon_itraffic;
	}

    /**
     * Setter tambon_itraffic
     * @param {TambonItraffic[] } value
     */
	public set tambon_itraffic(value: TambonItraffic[] ) {
		this._tambon_itraffic = value;
	}

    /**
     * Setter ticket_transport_status
     * @param {TicketTransportStatus[] } value
     */
     public set ticketTransportStatus(value: TicketTransportStatus[] ) {
		this._ticketTransportStatus = value;
	}

}