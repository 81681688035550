export class UserDB {
    public static users = [
      {
        'ID':'1',
        'USER': 'admin',
        'password': 'DuMqCin4bmT/RYZH/nn6xw==',
        'title_id': '0',
        'firstname': 'แอดมิน',
        'lastname': 'นะคับ',
        'birthdate': null,
        'idcard': '1749900027100',
        'hat_number': 'ทดสอบ',
        'rank_short': 'ทดสอบ',
        'rank_long': 'ทดสอบ',
        'position_short': 'ทดสอบ',
        'position_long': 'ทดสอบ',
        'department_id': '0',
        'police_station_id': '1',
        'issuer': '0',
        'status': null,
        'created_by': null,
        'created_dt': null,
        'updated_by': '0',
        'updated_dt': '2017-02-01 11:26:24',
        'last_login_time': '2019-10-01 21:38:30',
        'signature': null,
        'ws_username': null,
        'ws_password': null,
        'role':{
            'role_id': '1',
            'role_name': 'ADMIN',
            'active': 'Y',
            'created_by': 'null',
            'created_dt': 'null',
            'updated_by': '1',
            'updated_dt': '22/1/2017 20:28',
        },
        'title':{
            'title_id': '1',
            'title': 'คุณ',
        }
      },
      {
        'ID': '1020',
        'USER': 'hw820',
        'password': 'DuMqCin4bmT/RYZH/nn6xw==',
        'title_id': '0',
        'firstname': 'วิศิษฎ์',
        'lastname': 'มินเสน',
        'birthdate': 'null',
        'idcard': '1539900041058',
        'hat_number': '820',
        'rank_short': 'พ.ต.ต.',
        'rank_long': 'พันตำรวจตรี',
        'position_short': 'สว.ส.ทล.2 กก.8',
        'position_long': 'สารวัตรสถานีตำรวจทางหลวง 2 กองกำกับการ 8กองบังคับการตำรวจทางหลวง',
        'department_id': '0',
        'police_station_id': '1',
        'issuer': '0',
        'status': 'null',
        'created_by': 'null',
        'created_dt': 'null',
        'updated_by': '1020',
        'updated_dt': '2018-02-04 22:40:28',
        'last_login_time': '2019-10-05 15:45:52',
        'signature': 'null',
        'ws_username': 'null',
        'ws_password': 'null',
        'role':{
            'role_id': '1',
            'role_name': 'ADMIN',
            'active': 'Y',
            'created_by': 'null',
            'created_dt': 'null',
            'updated_by': '1',
            'updated_dt': '22/1/2017 20:28',
        },
        'title':{
            'title_id': '1',
            'title': 'คุณ',
        }
      }
    ];
  }
