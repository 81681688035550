import { Component, OnInit, Inject, ChangeDetectorRef, ɵCodegenComponentFactoryResolver } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material';
import { FormGroup, FormControl, Validators, FormArray ,FormBuilder} from '@angular/forms';
import { LocationService } from '../../../shared/services/location.service';
import { InitDataProvider } from '../../../shared/provider/initdata-provider';
import { ProvinceTransportService } from '../../../shared/services/province-transport.service';
import { ProvinceTransport } from '../../../shared/models/province-transport.model';
import { Location } from '../../../shared/models/location.model';
import { ProvincialPolice } from '../../../shared/models/provincial-police.model';
import { Subject } from 'rxjs';
import { MDBModalRef } from 'ng-uikit-pro-standard';
import { OptionObj } from '../../../shared/models/option-obl.model';
import { PoliceStation } from '../../../shared/models/police-station.model';
import { Tambon } from '../../../shared/models/tambon.model';
import { BaseComponent } from '../../../shared/components/base-component';
import { NgxPermissionsService } from 'ngx-permissions';
import { UserService } from '../../../shared/services/user.service';
import { AmphurTransport } from '../../../shared/models/amphur-transport.model';
import { Amphur } from '../../../shared/models/amphur.model';
import { AdminLayoutSidebarLargeComponent } from 'src/app/shared/components/layouts/admin-layout-sidebar-large/admin-layout-sidebar-large.component';
import { LocationDetail } from 'src/app/shared/models/location-detail.model';

@Component({
  selector: 'app-location-mgmt-add',
  templateUrl: './location-mgmt-add.component.html',
  styleUrls: ['./location-mgmt-add.component.scss']
})
export class LocationMgmtAddComponent extends BaseComponent implements OnInit {
  dataForm: FormGroup;
  amphur = [];
  tambon = [];

  LocationMgmt: Location
  provinceTransports: ProvinceTransport[];
  // filteredProvince = [];
  searchForm: FormGroup;
  data: Location;
  action: Subject<any> = new Subject();
  isSubmit: boolean = false;

  optionObj: OptionObj;
  isLoading: boolean = false;

  filteredTumbon: Array<any>;
  filteredAmphur: Array<any>;
  filteredProvince: Array<any>;
  filteredRoadType: Array<any>;

  filteredProvince2: Array<any>;
  filteredProvincialPolice: Array<any>;
  provincialPoliceList: Array<any>;

  permissionName:string = "LocationMgt";
  filteredPolice: Array<any>;
  isCheckinLocation: boolean = false;
  isLocationExist: boolean = false;
  speed: any[];
  previousSpeed : number = 0;
  count : number = 0;

  constructor(
    public modalRef: MDBModalRef,
    private LocationSV : LocationService,
    private changeDetectorRefs: ChangeDetectorRef,
    private provinceServ: ProvinceTransportService,
    ngxPermissionServ: NgxPermissionsService,
    public initData: InitDataProvider,
    public userServ: UserService,
    private fb: FormBuilder
    ){
    super(ngxPermissionServ, initData, userServ);
  }

  ngOnInit() {
    if(this.data != null){
      this.LocationMgmt = this.data;
    }
    this.initForm();
    if(this.LocationMgmt != undefined){
      if (this.LocationMgmt.prv_code != undefined) {
        var province = this.initData.province.find(x=>x.prov_code==this.LocationMgmt.prv_code);
        this.filteredAmphur = this.initData.amphur.filter(x => x.amp_code == this.LocationMgmt.amp_code && x.prov_code == province.prov_code).map((amphur: Amphur) => {
          return { value: amphur.amp_code, label: amphur.name };
        });
        this.dataForm.get('amp_code').patchValue(this.LocationMgmt.amp_code);
        if(this.LocationMgmt.amp_code != undefined){
          this.filteredTumbon = this.initData.tambon.filter(x => x.amp_code == this.LocationMgmt.amp_code && x.prov_lic_code == province.prov_lic_code).map((tambon: Tambon) => {
            return { value: tambon.tam_code, label: tambon.name };
          });
        }
      }
    }
    this.filteredRoadType = [
      {value: 1, label: 'ถนน'},
      {value: 2, label: 'ทางหลวง'},
      {value: 3, label: 'มอเตอร์เวย์'},
    ]
    this.filteredProvince = this.initData.province.map(
      (prov: ProvinceTransport) => {
        return { value: prov.prov_code, label: prov.name };
      }
    )

    this.filteredProvince2 = this.initData.province.map(
      (prov: ProvinceTransport) => {
        return { value: prov.prov_code, label: prov.name };
      }
    )

    this.LocationSV.getProvincialPolice().subscribe(
      (pagedData) => {
        this.provincialPoliceList = pagedData.items;
        this.filteredProvincialPolice = this.provincialPoliceList.filter(x => x.province == this.LocationMgmt.prov_police_station_prv_code).map((provincialPolice: ProvincialPolice) => {
          return { value: provincialPolice.id, label: provincialPolice.thai_long_name };
        });
      })
  }

  private initForm() {
    this.changeDetectorRefs.markForCheck();
    let id = null;
    let location_id :string;
    let police_station_id:number;
    let soi :string;
    let cross :string;
    let road_number :string;
    let road_name: string;
    let at_kilometer :string;
    let tam_code :string;
    let amp_code :string;
    let prv_code :string;
    let create_user = undefined;
    let create_datetime = undefined
    let location_shortname :string;
    let road_type :number;
    let lane: LocationDetail[];
    let prov_police_station_prv_code : string;
    let prov_police_station_id : number
    if(this.hasPermission(this.permissionName+this.ownStationLevel)){
      police_station_id = this.userServ.curtrentUser.police_station_id;
    }else if(this.hasPermission(this.permissionName+this.allLevel)){
      this.filteredPolice = this.getPoliceStationPermission(this.permissionName+this.allLevel);
    }
    if (this.LocationMgmt != null && this.LocationMgmt != undefined) {
      id = this.LocationMgmt.id
      location_id = this.LocationMgmt.location_id;
      police_station_id = this.LocationMgmt.police_station_id;
      soi = this.LocationMgmt.soi;
      cross = this.LocationMgmt.cross;
      road_number = this.LocationMgmt.road_number;
      road_name = this.LocationMgmt.road_name;
      at_kilometer = this.LocationMgmt.at_kilometer;
      tam_code = this.LocationMgmt.tam_code;
      amp_code = this.LocationMgmt.amp_code;
      prv_code = this.LocationMgmt.prv_code;
      create_user = this.LocationMgmt.create_user;
      create_datetime = this.LocationMgmt.create_datetime;
      location_shortname = this.LocationMgmt.location_shortname;
      road_type = this.LocationMgmt.road_type;
      lane = this.LocationMgmt.location_detail
      prov_police_station_prv_code = this.LocationMgmt.prov_police_station_prv_code
      prov_police_station_id = this.LocationMgmt.prov_police_station_id
    }
    this.count = 1;
      this.dataForm = new FormGroup({
        'id' : new FormControl(id),
        'location_id': new FormControl(location_id, [Validators.required, Validators.maxLength(20)]),
        'police_station_id': new FormControl(police_station_id , [Validators.required]),
        // 'soi': new FormControl(soi, [Validators.required, Validators.maxLength(100)]),
        // 'cross': new FormControl(cross , [Validators.required, Validators.maxLength(40)]),
        // 'road_number': new FormControl(tam_code , [Validators.required, Validators.maxLength(50)]),
        'road_name': new FormControl(road_name , [Validators.required, Validators.maxLength(100)]),
        'at_kilometer': new FormControl(at_kilometer , [Validators.required, Validators.maxLength(50)]),
        'tam_code': new FormControl(tam_code , [Validators.required]),
        'amp_code': new FormControl(amp_code , [Validators.required]),
        'prv_code': new FormControl(prv_code , [Validators.required]),
        'location_shortname': new FormControl(location_shortname , [Validators.required, Validators.maxLength(45)]),
        'road_type': new FormControl(road_type , [Validators.required]),
        'lane' : this.fb.array([]),
        'prov_police_station_prv_code' : new FormControl(prov_police_station_prv_code),
        'prov_police_station_id'  : new FormControl(prov_police_station_id)
      });
      if(lane != undefined){
        lane.forEach(element => {
          (<FormArray>this.dataForm.get('lane')).push(this.addLaneFormGroup(element));  
        });
      this.previousSpeed = this.dataForm.get('lane').value.length;
      }else{
          (<FormArray>this.dataForm.get('lane')).push(this.addLaneFormGroup(false));  
      }

  }

  addLaneFormGroup(lane :any): FormGroup{
    if(!lane){
      return this.fb.group({
        lane: ++this.previousSpeed,
        speed_limit:[null,[Validators.required,Validators.min(1)]],
        speed_capture: [null,[Validators.required,Validators.min(1)]],
        speed_limit_transport: [null,[Validators.required,Validators.min(1)]],
        speed_capture_transport: [null,[Validators.required,Validators.min(1)]],
      });
    }else{
      return this.fb.group({
        lane: lane['lane'],
        speed_limit:[+lane['speed_limit'],[Validators.required,Validators.min(1)]],
        speed_capture: [+lane['speed_capture'],[Validators.required,Validators.min(1)]],
        speed_limit_transport: [+lane['speed_limit_transport'],[Validators.required,Validators.min(1)]],
        speed_capture_transport: [+lane['speed_capture_transport'],[Validators.required,Validators.min(1)]],
      });
    }
  }

  onSubmit(){
    this.dataForm.value.lane.forEach(element => {
      element.speed_capture = +element.speed_capture
      element.speed_capture_transport = +element.speed_capture_transport
      element.speed_limit = +element.speed_limit
      element.speed_limit_transport = +element.speed_limit_transport
    });
    if (this.dataForm.valid) {
      this.isSubmit = true;


      if(this.dataForm.value.id == null) { // create
        this.LocationSV.save(this.dataForm.value).subscribe(
          (res)=>{
            if(res){
              this.action.next(true);
            }else{
              this.action.next(false);
            }
            this.isSubmit = false;
            this.modalRef.hide();
          }
        ,(err)=>{
          console.log(err)
          this.modalRef.hide();
        });
      }else{ // update
        this.LocationSV.update(this.dataForm.value,this.dataForm.value.id).subscribe(
          (res)=>{
            if(res){
              this.action.next(true);
            }else{
              this.action.next(false);
            }
            this.isSubmit = false;
            this.modalRef.hide();
          }
        ,(err)=>{
          console.log(err)
          this.modalRef.hide();
        });
      }
      
      
    } else {
      this.validateAllFormFields(this.dataForm);
      this.validateAllFormArrays(this.dataForm);
    }
  }

  onChangeProvince(provInput: any) {
    //get amphur & province & zipcode
    this.filteredTumbon = [];
    this.dataForm.get('tam_code').patchValue(null);
    this.dataForm.get('amp_code').patchValue(null);
    var province = this.initData.province.filter(x => x.prov_code == provInput)[0];
    if (province != null && province != undefined) {
      this.filteredAmphur = this.initData.amphur.filter(x => x.prov_code == province.prov_code).map((amphur: Amphur) => {
        return { value: amphur.amp_code, label: amphur.name };
      });
      if (this.filteredAmphur.length == 1) {
        this.dataForm.get('amp_code').patchValue(this.filteredAmphur[0].value);
      } else if (this.filteredAmphur.length == 0) {
        this.filteredAmphur = this.initData.amphur.map((amphur: Amphur) => {
          return { value: amphur.amp_code, label: amphur.name };
        });
      }
    }
  }


  onChangeProvince2(provInput: any) {
    this.dataForm.get('prov_police_station_id').patchValue(null);
    var province = this.initData.province.filter(x => x.prov_code == provInput)[0];
    if (province != null && province != undefined) {
      this.LocationSV.getProvincialPolice().subscribe(
        (pagedData) => {
          this.provincialPoliceList = pagedData.items;
          this.filteredProvincialPolice = this.provincialPoliceList.filter(x => x.province == province.prov_code).map((provincialPolice: ProvincialPolice) => {
            return { value: provincialPolice.id, label: provincialPolice.thai_long_name };
          });
        })
    }
  }


  onChangeAmphur(amphurInput: any) {
    this.dataForm.get('tam_code').patchValue(null);
    if (this.dataForm.get('prv_code').value != null && this.dataForm.get('prv_code').value != undefined) {
      if (amphurInput != null && amphurInput != undefined) {
        console.log("amphurInput");
        console.log(amphurInput);
        this.filteredTumbon = this.initData.tambon.filter(x => x.amp_code == amphurInput && x.prov_code == this.dataForm.get('prv_code').value).map((tambon: Tambon) => {
          return { value: tambon.tam_code, label: tambon.name };
        });
        if (this.filteredTumbon.length == 1) {
          this.dataForm.get('tam_code').patchValue(this.filteredTumbon[0].value);
        }
      }
    }
  }

  checkExistLocationId(locationId: string) {
    if (locationId.length > 0 && this.dataForm.get('location_id').valid) {
      if(this.LocationMgmt != undefined){
        if(this.LocationMgmt.location_id != locationId){
          this.isCheckinLocation = true;
          this.LocationSV.checkExistLocation(locationId).subscribe(
            (res: boolean) => {
              console.log(res);
              if(res!=true){
                this.dataForm.get('location_id').setErrors({'duplicateuser': true});
                this.isLocationExist = false;
              }else{
                this.dataForm.get('location_id').setErrors(null);
                this.isLocationExist = true;
              }
              this.isCheckinLocation = false;
            }
          );
        }
      }else{
        this.isCheckinLocation = true;
        this.LocationSV.checkExistLocation(locationId).subscribe(
          (res: boolean) => {
            console.log(res);
            if(res!=true){
              this.dataForm.get('location_id').setErrors({'duplicateuser': true});
              this.isLocationExist = false;
            }else{
              this.dataForm.get('location_id').setErrors(null);
              this.isLocationExist = true;
            }
            this.isCheckinLocation = false;
          }
        );
      }
    } else {
      this.isLocationExist = null;
      this.isCheckinLocation = false;
    }
  }

  addAddress(){
    this.count = this.count + 1;
    (<FormArray>this.dataForm.get('lane')).push(this.addLaneFormGroup(false));
  }

  removeAddress(i: number) {
    this.count = this.count - 1;
    (<FormArray>this.dataForm.get('lane')).removeAt(i)
    this.previousSpeed = this.dataForm.get('lane').value.length;
  }

  modalClose(){
    // this.action.next(false);
    this.modalRef.hide();
  }
}
