export class PoliceStationDB {
    public static policeStations = [
      {
        'police_station_id': '1',
        'station_id': '54246',
        'thai_short_name': 'ส.ทล.2 กก.8 บก.ทล.',
        'thai_long_name': 'สถานีตำรวจทางหลวง 2 กองกำกับการ 8 กองบังคับการตำรวจทางหลวง',
        'eng_short_name': 'HWPD82',
        'eng_long_name': 'Highway Police Station 2  Sub-Division 8',
        'station_address1': 'สถานีตำรวจทางหลวง 2 กองกำกับการ 8',
        'station_address2': 'กองบังคับการตำรวจทางหลวง  เลขที่ 559',
        'station_address3': 'ถ.รามอินทรา กม.10 แขวงรามอินทรา',
        'station_address4': 'เขตคันนายาว กรุงเทพฯ 10230',
        'post_office': 'ปณ.จรเข้บัว 10230',
        'post_address1': 'post_address1',
        'post_address2': 'post_address2',
        'post_address3': 'post_address3',
        'post_address4': 'post_address4',
        'post_month1': 'ชำระค่าฝากส่งเป็นรายเดือน',
        'post_month2': 'ใบอนุญาตเลขที่ 1/2554',
        'post_month3': 'ปณจ.จรเข้บัว 10230',
        'tel': '02-510-7237',
        'payment_point1': 'payment_point1',
        'payment_point2': 'payment_point2',
        'payment_point3': 'payment_point3',
        'payment_point4': 'payment_point4',
        'payment_point5': 'payment_point5',
        'doc_no': 'ตช.0026.382/',
        'subpeona_user_id': '1000050',
      },
      {
        'police_station_id': '2',
        'station_id': '50983',
        'thai_short_name': 'ส.ทล.1 กก.3 บก.ทล.',
        'thai_long_name': 'สถานีตำรวจทางหลวง 1 กองกำกับการ 3 กองบังคับการตำรวจทางหลวง',
        'eng_short_name': 'HWPD31',
        'eng_long_name': 'Highway Police Station 1  Sub-Division 3',
        'station_address1': 'สถานีตำรวจทางหลวง 1 กองกำกับการ 3',
        'station_address2': 'กองบังคับการตำรวจทางหลวง',
        'station_address3': 'ถนนเทพรัตน ตำบลบางปะกง',
        'station_address4': 'อำเภอบางปะกง จังหวัดฉะเชิงเทรา 24130',
        'post_office': 'ปณ.บางปะกง 24130',
        'post_address1': 'post_address1',
        'post_address2': 'post_address2',
        'post_address3': 'post_address3',
        'post_address4': 'post_address4',
        'post_month1': 'ชำระค่าฝากส่งเป็นรายเดือน',
        'post_month2': 'ใบอนุญาตเลขที่ XXXXXXX',
        'post_month3': 'ปณ XXXXXXXXX',
        'tel': '038-533434 ,Fax.038-531221',
        'payment_point1': 'payment_point1',
        'payment_point2': 'payment_point2',
        'payment_point3': 'payment_point3',
        'payment_point4': 'payment_point4',
        'payment_point5': 'payment_point5',
        'doc_no': null,
        'subpeona_user_id': null,
      },
    ];
  }
