import { Directive, OnDestroy, Input, ElementRef } from '@angular/core';

@Directive({
  selector: 'img[retrySrc]',
  host: {
    '(error)': 'loadError()',
    '(load)': 'clearTimer()',
  }
})
//thx https://stackoverflow.com/questions/54311176/angular-6-catch-missing-image-source-then-try-to-reset-the-same-source
export class RetrySrcDirective implements OnDestroy {
  private _retrySrc: string;
  private _timer;

  constructor(
    private element: ElementRef<HTMLImageElement>,
  ) {
  }

  @Input()
  set retrySrc(value: string) {
    this.clearTimer();
    console.log("retry");
    this._retrySrc = value;
    this.element.nativeElement.src = value; //each set on src triggers a new request in google Chrome
  }

  ngOnDestroy() {
    this.clearTimer();
  }

  loadError() {
    this.clearTimer();
    this._timer = setTimeout(() => this.element.nativeElement.src = this._retrySrc, 5000);
  }

  clearTimer() {
    if (this._timer) {
      clearTimeout(this._timer);
      delete this._timer
    }
  }
}