import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { NgxPermissionsService, NgxRolesService } from 'ngx-permissions';

export interface IMenuItem {
    id?: string;
    title?: string;
    description?: string;
    type: string;       // Possible values: link/dropDown/extLink
    name?: string;      // Used as display text for item and title for separator type
    state?: string;     // Router state
    icon?: string;      // Material icon name
    tooltip?: string;   // Tooltip text
    disabled?: boolean; // If true, item will not be appeared in sidenav.
    sub?: IChildItem[]; // Dropdown items
    badges?: IBadge[];
    active?: boolean;
    permission?: string|string [];
}
export interface IChildItem {
    id?: string;
    parentId?: string;
    type?: string;
    name: string;       // Display text
    state?: string;     // Router state
    icon?: string;
    sub?: IChildItem[];
    active?: boolean;
    permission?: string|string [];
}

interface IBadge {
    color: string;      // primary/accent/warn/hex color codes(#fff000)
    value: string;      // Display text
}

interface ISidebarState {
    sidenavOpen?: boolean;
    childnavOpen?: boolean;
}

@Injectable({
    providedIn: 'root'
})
export class NavigationService {
    public sidebarState: ISidebarState = {
        sidenavOpen: true,
        childnavOpen: false
    };
    constructor(
        private ngxPermissionServ: NgxPermissionsService,
        private ngxRoleServ: NgxRolesService
        ) {
    }

    defaultMenu: IMenuItem[] = [
        {   
            name: 'หน้าแรก',
            description: 'เมนูทั้งหมด',
            type: 'link',
            icon: 'i-Home1',
            state: '',
        },
        // {   
        //     name: 'แผงควบคุม',
        //     description: 'สรุปภาพรวมของหน่วยงาน',
        //     type: 'link',
        //     icon: 'i-Dashboard',
        //     state: '/dashboard', 
        // },
        {
            name: 'รายงาน',
            description: 'รายงานที่เกี่ยวข้อง',
            type: 'dropDown',
            icon: 'i-File',
            state: '/report', 
            sub: [
                { icon: 'i-File', name: 'รายงานตามผู้บันทึกใบสั่ง', state: '/report/location', type: 'link', permission: 'ReportLocation' },
                { icon: 'i-File', name: 'รายงานตามการออกใบสั่งของสถานี', state: '/report/station', type: 'link', permission: 'ReportStation' },
                { icon: 'i-File', name: 'รายงานตามการออกบาร์โค้ดกรุงไทย', state: '/report/ktb', type: 'link', permission: 'ReportBarcode' }, 
            ],
            permission: ['ReportLocation','ReportStation','ReportBarcode']
        },
        {   
            name: 'ข้อมูลกระทำผิด',
            description: 'นำเข้าข้อมูลกระทำผิด ตรวจสอบรายละเอียดความถูกต้องของข้อมูล',
            type: 'dropDown',
            icon: 'i-Jeep',
            state: '/search_incident', 
            sub: [
                { icon: 'i-Download', name: 'นำเข้าข้อมูลกระทำผิด', state: '/search_incident', type: 'link', permission: 'ViewIncidentImport' },
                { icon: 'i-Pen-5', name: 'ตรวจสอบข้อมูลกระทำผิด', state: '/approve_ticket/ticketviewlist', type: 'link', permission: ['AddTicket','ViewTicket'] },
                { icon: 'i-Download', name: 'นำเข้าข้อมูลกระทำผิดกล้องเคลื่อนที่', state: '/camera_move', type: 'link', permission: 'ViewIncidentCameramoveImport' },
                { icon: 'i-Pen-5', name: 'ตรวจสอบข้อมูลกระทำผิดกล้องเคลื่อนที่', state: '/approve_ticket/ticketcameramovelist', type: 'link', permission: ['AddCameramoveTicket','ViewCameramoveTicket'] },
            ],
            permission: ['AddTicket','ViewIncidentImport', 'ViewTicket', 'AddCameramoveTicket','ViewIncidentCameramoveImport','ViewCameramoveTicket']
        },
        {   
            name: 'ใบสั่ง',
            description: 'รายละเอียดข้อมูลใบสั่ง และใบเตือนและกระบวนการจัดพิมพ์ใบสั่ง',
            type: 'dropDown',
            icon: 'i-Letter-Open',
            state: '/search_ticket', 
            sub: [
                { icon: 'i-Data-Search', name: 'ค้นหาใบสั่ง', state: '/search_ticket', type: 'link', permission: 'ViewSearchTicket' },
                { icon: 'i-Data-Search', name: 'ค้นหาใบนำส่งไปรษณีย์', state: '/portal_ear', type: 'link', permission: 'ViewSearchTicket' },
                { icon: 'i-Envelope', name: 'ออกใบเตือน', state: '/warning_ticket', type: 'link', permission: 'WariningTicket' },
            ],
            permission: ['ViewSearchTicket', 'WariningTicket']
        },
        {   
            name: 'กระทำความผิดซ้ำซาก',
            description: '',
            type: 'dropDown',
            icon: 'fas fa-traffic-light',
            state: 'offense', 
            sub: [
                { icon: 'i-File', name: 'ระบบติดตามพฤติกรรมผู้กระทำความผิดซ้ำซาก', state: 'offense', type: 'extLink', permission: 'UseOffender' },
            ],
            permission: ['UseOffender'],
        },
        {   
            name: 'ตั้งค่า',
            description: 'จัดการข้อมูลต่างๆ ของระบบ',
            type: 'dropDown',
            icon: 'i-Gears',
            state: '/config_camera_pic', 
            sub: [
                { icon: 'i-Building', name: 'ข้อมูลหน่วยงาน', state: '/station_mgmt', type: 'link', permission: 'PoliceStationMgt' },
                { icon: 'i-Male', name: 'ข้อมูลเจ้าหน้าที่ตำรวจ', state: '/policeofficer_mgmt', type: 'link', permission: 'OfficerMgt' },
                { icon: 'i-Danger', name: 'ข้อมูลรถยกเว้น (ส่วนกลาง)', state: '/exception_car', type: 'link', permission: ['ExceptionCentralMgt'] },
                { icon: 'i-Warning-Window', name: 'ข้อมูลรถยกเว้น (สถานี)', state: '/exception_car_station', type: 'link', permission: ['ExceptionStationMgt'] },
                { icon: 'i-Camera', name: 'ข้อมูลกล้อง', state: '/camera_info', type: 'link', permission: 'CameraMgt' },
                { icon: 'i-Map2', name: 'ข้อมูลสถานที่กระทำผิด', state: '/location_mgmt', type: 'link', permission: 'LocationMgt' },
                { icon: 'i-Map2', name: 'ข้อมูล สภ.ท้องที่เกิดเหตุ', state: '/provincial_police_station', type: 'link', permission: 'ผู้ดูแลระบบ' },
                { icon: 'i-File-Clipboard-Text--Image', name: 'Resolution รูป', state: '/config_res', type: 'link', permission: 'ผู้ดูแลระบบ' },
                { icon: 'i-File-Clipboard-Text--Image', name: 'ตั้งค่าแบบแยกรูปภาพ', state: '/config_camera_pic', type: 'link', permission: 'ผู้ดูแลระบบ' },
                { icon: 'i-File-Clipboard-Text--Image', name: 'เช็คจำนวนกระทำผิดซ้ำซาก', state: '/config_offender', type: 'link', permission: 'ผู้ดูแลระบบ' },
            ],
            permission: ['PoliceStationMgt', 'OfficerMgt', 'ExceptionCentralMgt', 'ExceptionStationMgt', 'CameraMgt', 'LocationMgt', 'ผู้ดูแลระบบ']
        },
        {   
            name: 'จัดการผู้ใช้งาน',
            description: 'ดูรายชื่อผู้ใช้งาน เพิ่ม ลบ หรือแก้ไขข้อมูลผู้ใช้งาน',
            type: 'dropDown',
            icon: 'i-Male',
            state: '/user', 
            sub: [
                { icon: 'i-Male', name: 'รายชื่อผู้ใช้งาน', state: '/user', type: 'link', permission: 'ViewUser' },
                { icon: 'i-Lock-User', name: 'ระดับการใช้งาน (Role Permission)', state: '/permission_mgmt', type: 'link', permission: 'ผู้ดูแลระบบ' },
            ],
            permission: ['ViewUser', 'ผู้ดูแลระบบ']
        }, {   
            name: 'About',
            description: 'เกี่ยวกับระบบ',
            type: 'link',
            icon: 'i-Information',
            state: '/about',
        },
    ];


    // sets iconMenu as default;
    menuItems = new BehaviorSubject<IMenuItem[]>(this.defaultMenu);
    // navigation component has subscribed to this Observable
    menuItems$ = this.menuItems.asObservable();

    // You can customize this method to supply different menu for
    // different user type.
    publishNavigationChange(nav: IMenuItem[]) {
        nav = this.defaultMenu;//fix bug (tmp), will change later
        let permissions = this.ngxPermissionServ.getPermissions();
        let roles = this.ngxRoleServ.getRoles();
        let role = Object.keys(roles)[0];
        var permMenus:IMenuItem[] = [];
        nav.forEach(
            (menu: IMenuItem)=>{
                let permMenu:IMenuItem = null;
                let subMenuMain:IChildItem[] = [];
                if(menu.permission == null || menu.permission == undefined){
                    permMenu = menu;
                }else if(Object.keys(permissions).find(x=>x == menu.permission || menu.permission.indexOf(x) > -1)){
                    permMenu = menu;
                }
                if(menu.sub != undefined){
                    menu.sub.forEach(
                        (subMenu: IChildItem)=>{
                            if(Object.keys(permissions).find(x=>subMenu.permission.indexOf(x) > -1)){
                                subMenuMain.push(subMenu);
                            }else if(subMenu.permission == role){
                                subMenuMain.push(subMenu);
                            }
                        }
                    )
                }
                if(permMenu != null){
                    if(subMenuMain.length>0){
                        permMenu.sub = subMenuMain;
                    }
                    permMenus.push(permMenu);
                }
            }
        );
        this.menuItems.next(permMenus);
    }
}
