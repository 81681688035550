export class RoleDB {
    public static roles = [
      {
        'roleId': '1',
        'roleName': 'ADMIN',
        'active': 'Y',
        'created_by': 'null',
        'created_dt': 'null',
        'updated_by': '1',
        'updated_dt': '2016-12-29 09:49:58',
      },
      {
        'roleId': '2',
        'roleName': 'OPERATION',
        'active': 'Y',
        'created_by': 'null',
        'created_dt': 'null',
        'updated_by': '1',
        'updated_dt': '2016-12-29 09:49:58',
      },
      {
        'roleId': '3',
        'roleName': 'OFFICER',
        'active': 'Y',
        'created_by': 'null',
        'created_dt': 'null',
        'updated_by': '999999',
        'updated_dt': '2017-08-03 15:52:52',
      },
      {
        'roleId': '4',
        'roleName': 'STAFF',
        'active': 'Y',
        'created_by': 'null',
        'created_dt': 'null',
        'updated_by': '1020',
        'updated_dt': '2017-03-20 17:20:54',
      },
    ];
  }
