import { HttpParams } from "@angular/common/http";
import { RolePermission } from "../models/role-permission.model";

export class BaseService {
    exceptKey = [
        'speed_capture',
        'speed_capture_transport'
    ];

    private permissions = [
        { id: 1, name: 'AddincidentImport' },
        { id: 2, name: 'ViewIncidentImport' },
        { id: 3, name: 'AddTicket' },
        { id: 4, name: 'ViewTicket' },
        { id: 5, name: 'editTicket' },
        { id: 6, name: 'ViewSearchTicket' },
        { id: 7, name: 'WariningTicket' },
        { id: 8, name: 'PoliceStationMgt' },
        { id: 9, name: 'OfficerMgt' },
        { id: 10, name: 'ExceptionCentralMgt' },
        { id: 11, name: 'ExceptionStationMgt' },
        { id: 12, name: 'CameraMgt' },
        { id: 13, name: 'LocationMgt' },
        { id: 14, name: 'ViewUser' },
        { id: 15, name: 'AddUser' },
        { id: 16, name: 'PermissionMgt' },
        { id: 17, name: 'ReportLocation' },
        { id: 18, name: 'ReportStation' },
        { id: 19, name: 'ReportBarcode'},
        { id: 20, name: 'AddincidentCameramoveImport' },
        { id: 21, name: 'ViewIncidentCameramoveImport' },
        { id: 22, name: 'AddCameramoveTicket' },
        { id: 23, name: 'ViewCameramoveTicket' },
        { id: 24, name: 'EditCameramoveTicket' },
        { id: 25, name: 'UseOffender' },

    ];

    setParams(params: HttpParams, reqOptions: any) {
        if (!reqOptions) return params;
        if (reqOptions.key && reqOptions.value) {
            return params.set(reqOptions.key, reqOptions.value);
        }
        for (let key of Object.keys(reqOptions)) {
            if (reqOptions[key] != null) {
                params = params.set(key, reqOptions[key]);
            }
        }
        return params;
    }

    removeNullBody(data: any) {
        for (let key of Object.keys(data)) {
            if ((data[key] == null || data[key] == 'null' || data[key].length == 0 ) && !this.exceptKey.includes(key)) {
                delete data[key];
            }
        }
        return data;
    }

    mapPermission(permissionId: number) {
        return this.permissions.filter(x => x.id == Number(permissionId))[0].name;
    }

    getPermissionId(permissionName:string){
        return this.permissions.filter(x => x.name == permissionName)[0].id;
      }
}
