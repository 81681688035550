import { Component, OnInit } from "@angular/core";
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { NavigationService } from "src/app/shared/services/navigation.service";
import { SearchService } from "src/app/shared/services/search.service";
import { AuthService } from "src/app/shared/services/auth.service";
import { User } from "../../../../models/user.model";
import { Router, ActivatedRoute } from '@angular/router';
import { LayoutsModule } from "../../layouts.module";
import { HeaderService } from 'src/app/shared/services/header.service';
import { UserService } from 'src/app/shared/services/user.service';
import { MDBModalService, MDBModalRef } from "ng-uikit-pro-standard";
import { EditWebserviceUserComponent } from "../../../../../views/dialog/edit-webservice-user/edit-webservice-user.component";
import { InitDataProvider } from '../../../../../shared/provider/initdata-provider';
// import { Header } from 'src/app//shared/models/header.model';

import { PoliceStation } from '../../../../../shared/models/police-station.model';
import { ConstantPool } from "@angular/compiler";

// add new
import { NgxPermissionsService, NgxRolesService } from 'ngx-permissions';
import { StorageMap } from '@ngx-pwa/local-storage';
import { LocalStoreService } from "../../../../services/local-store.service";
@Component({
  selector: "app-header-sidebar-compact",
  templateUrl: "./header-sidebar-compact.component.html",
  styleUrls: ["./header-sidebar-compact.component.scss"]
})
export class HeaderSidebarCompactComponent implements OnInit {
  dataForm: FormGroup;
  searchForm: FormGroup;
  filteredPoliceStation: Array<any>;
  filteredPoliceStationAll: Array<any>;
  notifications: any[];
  user: User;
  canAccessWs:boolean = false;
  modalRef: MDBModalRef;
  curentMonth : any;
  ticket_month_total : number;
  ticket_month_pass : number;
  ticket_month_fail : number;
  ticket_today_total : number;
  ticket_today_pass : number;
  ticket_today_fail : number;

  constructor(
    private navService: NavigationService,
    public searchService: SearchService,
    private modalService: MDBModalService,
    private auth: AuthService,
    private router: Router,
    private userService: UserService,
    private headerService: HeaderService,
    public initData: InitDataProvider,
    private ps:NgxPermissionsService, 
    private rs: NgxRolesService,
    private storage: StorageMap,
    private store: LocalStoreService,
  ) {
    // this.notifications = [
    //   {
    //     icon: "file-pdf",
    //     // title:"ดาวน์โหลดสำเร็จ",
    //     badge: "3",
    //     text: "TK_20051_16001_All_20210506_20210507.pdf",
    //     time: new Date(),
    //     status: "danger",
    //     link: "api/./upload/94DCEA1C-DA8C-4201-9815-7E11FE7BC825.pdf",
    //     id:1
    //   },
    //   {
    //     icon: "file-pdf",
    //     title:"ดาวน์โหลดสำเร็จ",
    //     badge: "$4036",
    //     text: "TK_16002_22001_All_20210506_20210507.pdf",
    //     time: new Date("11/11/2018"),
    //     status: "danger",
    //     link: "api/./upload/94DCEA1C-DA8C-4201-9815-7E11FE7BC825.pdf",
    //     id:2
    //   },
    //   {
    //     icon: "file-excel",
    //     title:"ดาวน์โหลดสำเร็จ",
    //     text: "RU_34001_35004_All_20210505_20210510.xlsx",
    //     time: new Date("11/10/2018"),
    //     status: "success",
    //     link: "api/./upload/94DCEA1C-DA8C-4201-9815-7E11FE7BC825.pdf",
    //     id:3
    //   },
    //   {
    //     icon: "file-pdf",
    //     title:"ดาวน์โหลดสำเร็จ",
    //     text: "20010 สถานีอะไรเอ่ย.pdf",
    //     time: new Date("11/08/2018"),
    //     status: "danger",
    //     link: "api/./upload/94DCEA1C-DA8C-4201-9815-7E11FE7BC825.pdf",
    //     id:4
    //   },
    //   {
    //     icon: "file-pdf",
    //     title:"ดาวน์โหลดสำเร็จ",
    //     badge: "Resolved",
    //     text: "TK_34001_35004_All_20210505_20210510.pdf",
    //     time: new Date("11/06/2018"),
    //     status: "danger",
    //     link: "api/./upload/94DCEA1C-DA8C-4201-9815-7E11FE7BC825.pdf",
    //     id:5
    //   }
    // ];
  }

  onInitForm(){
    this.userService.myStation().subscribe(
      (res) => {
        this.filteredPoliceStation = Object.keys(res).map(function (key) {
          return {value:res[key].id,label:res[key].thai_short_name};
        })
      }
    );

    this.filteredPoliceStationAll = [
      {value: "null", label: 'สถานีทั้งหมด'},
    ]

    this.dataForm = new FormGroup({
      // 'police_station_id': new FormControl({ value: police_station_choose, disabled: (role_id==1) }, (role_id==1)?Validators.nullValidator:Validators.required),
      'police_station_id': new FormControl({ value: this.userService.curtrentUser.police_station_id ,disabled: (1!=1)}, Validators.required),
    })
  }

  ngOnInit() {
    this.onInitForm();
    setInterval(() => {
      // this.setNotificafion(); 
      console.log("is working .. ")
    }, 8000);

    this.user = this.userService.curtrentUser
    this.canAccessWs = this.user.can_access_ws;
    this.userService.currentUserObs.subscribe(
      (res)=>{
        this.user = res;
        this.canAccessWs = this.user.can_access_ws;
      }
    );

    var month = new Date().getMonth()+1
    var monthName = ""
    switch(month) {
      case 1:
          monthName = "มกราคม"
        break;
      case 2:
          monthName = "กุมภาพันธ์"
        break;
      case 3:
          monthName = "มีนาคม"
        break;
      case 4:
          monthName = "เมษายน"
        break;
      case 5:
          monthName = "พฤษภาคม"
        break;
      case 6:
          monthName = "มิถุนายน"
        break;
      case 7:
          monthName = "กรกฎาคม"
        break;
      case 8:
          monthName = "สิงหาคม"
        break;
      case 8:
          monthName = "กันยายน"
        break;
      case 10:
          monthName = "ตุลาคม"
        break;
      case 11:
          monthName = "พฤศจิกายน"
        break;
      case 12:
          monthName = "ธันวาคม"
        break;
      default:
        // code block
    }
    this.curentMonth =  monthName
    console.log("=====================================")
    this.headerService.countTicketByUser(this.user.id).subscribe(pagedData => {
      this.ticket_month_total = pagedData.ticket_month_total
      this.ticket_month_pass = pagedData.ticket_month_pass
      this.ticket_month_fail = pagedData.ticket_month_fail
      this.ticket_today_total = pagedData.ticket_today_total
      this.ticket_today_pass = pagedData.ticket_today_pass
      this.ticket_today_fail = pagedData.ticket_today_fail
      // console.log(pagedData.ticket_month_total)
      // console.log(pagedData.ticket_month_pass)
      // console.log(pagedData.ticket_month_fail)
      // console.log(pagedData.ticket_today_total)
      // console.log(pagedData.ticket_today_pass)
      // console.log(pagedData.ticket_today_fail)
    })

  //  this.headerService.countTicketByUser().subscribe((res: Header) => {
  //   console.log(res)
  //     // if (res) {
  //       // var dd = res["ticket_month_pass"]
  //       // console.log(header.ticket_month_pass)
  //   //  console.log(res.ticket_month_total)
  //     // }
  //     // this.page.size_per_page = pagedData.size_per_page;//size
  //     // this.page.current_page = pagedData.current_page;//pageNumber
  //     // this.page.total_item = pagedData.total_item;//totalElements
  //     // this.page.total_page = pagedData.total_page;//total_page
  //     // this.rows = pagedData.items;
  //     // this.isLoading = false;
  //   })
  }

  onChangeStation(id:any){
    this.searchForm = new FormGroup({
      'location_id': new FormControl(null),
    });
    this.userService.changeStation(id).subscribe((user)=>{
      if(user != false){
        
        // loadLogin
        this.userService.loadLogin().then(
          (res:any) => {
            if(res){
              // if (this.searchForm.get('police_station_id') != null) {
                this.searchForm.get('location_id').patchValue(0);
              // }
              //clear all storage
              // this.storage.clear().subscribe(() => {
                this.ps.flushPermissions();
                this.rs.flushRoles();
                this.store.setItem("demo_login_status", false);
              // })
              console.log("login data");
              console.log(res);

              this.storage.delete("currentUser").subscribe()
              this.storage.set("currentUser", user).subscribe()

              this.ps.loadPermissions(res.perm);
              this.rs.addRole(res.name, res.perm);
              this.storage.delete('user').subscribe()
              this.storage.set('user', res).subscribe(
                  () => {
                      console.log("is navigate : true ")
                      setTimeout(function(){
                        location.reload();
                      },1000);
                  }
              );
            }
          },(err)=>{
            console.log(err)
          })

          
      }

      /*this.ps.loadPermissions(data.perm);
      this.rs.addRole(data.name, data.perm);*/
      /*this.storage.set('user', data).subscribe(
          () => {
              this.router.navigate(['/']);
          }
      );*/
    },(err)=>{
      console.log(err)
    })
  }

  // setNotificafion () {
  //   this.notifications = [
  //     {
  //       icon: "file-pdf",
  //       title:"จ้าาาาา",
  //       badge: "3",
  //       text: "20010 สถานีอะไรเอ่ย.pdf",
  //       time: new Date(),
  //       status: "danger",
  //       link: "api/./upload/94DCEA1C-DA8C-4201-9815-7E11FE7BC825.pdf",
  //       id:1
  //     },
  //     {
  //       icon: "file-pdf",
  //       title:"จ้าาาาา",
  //       badge: "$4036",
  //       text: "20010 สถานีอะไรเอ่ย.pdf",
  //       time: new Date("11/11/2018"),
  //       status: "danger",
  //       link: "api/./upload/94DCEA1C-DA8C-4201-9815-7E11FE7BC825.pdf",
  //       id:2
  //     },
  //     {
  //       icon: "file-pdf",
  //       title:"จ้าาาาา",
  //       text: "20010 สถานีอะไรเอ่ย.pdf",
  //       time: new Date("11/10/2018"),
  //       status: "danger",
  //       link: "api/./upload/94DCEA1C-DA8C-4201-9815-7E11FE7BC825.pdf",
  //       id:3
  //     },
  //     {
  //       icon: "file-pdf",
  //       title:"จ้าาาาา",
  //       text: "20010 สถานีอะไรเอ่ย.pdf",
  //       time: new Date("11/08/2018"),
  //       status: "danger",
  //       link: "api/./upload/94DCEA1C-DA8C-4201-9815-7E11FE7BC825.pdf",
  //       id:4
  //     },
  //     {
  //       icon: "file-pdf",
  //       title:"จ้าาาาา",
  //       badge: "Resolved",
  //       text: "20010 สถานีอะไรเอ่ย.pdf",
  //       time: new Date("11/06/2018"),
  //       status: "danger",
  //       link: "api/./upload/94DCEA1C-DA8C-4201-9815-7E11FE7BC825.pdf",
  //       id:5
  //     },
  //     {
  //       icon: "file-pdf",
  //       title:"จ้าาาาา",
  //       badge: "3",
  //       text: "20010 สถานีอะไรเอ่ย.pdf",
  //       time: new Date(),
  //       status: "danger",
  //       link: "api/./upload/94DCEA1C-DA8C-4201-9815-7E11FE7BC825.pdf",
  //       id:1
  //     },
  //     {
  //       icon: "file-pdf",
  //       title:"จ้าาาาา",
  //       badge: "$4036",
  //       text: "20010 สถานีอะไรเอ่ย.pdf",
  //       time: new Date("11/11/2018"),
  //       status: "danger",
  //       link: "api/./upload/94DCEA1C-DA8C-4201-9815-7E11FE7BC825.pdf",
  //       id:2
  //     },
  //     {
  //       icon: "file-pdf",
  //       title:"จ้าาาาา",
  //       text: "20010 สถานีอะไรเอ่ย.pdf",
  //       time: new Date("11/10/2018"),
  //       status: "danger",
  //       link: "api/./upload/94DCEA1C-DA8C-4201-9815-7E11FE7BC825.pdf",
  //       id:3
  //     },
  //     {
  //       icon: "file-pdf",
  //       title:"จ้าาาาา",
  //       text: "20010 สถานีอะไรเอ่ย.pdf",
  //       time: new Date("11/08/2018"),
  //       status: "danger",
  //       link: "api/./upload/94DCEA1C-DA8C-4201-9815-7E11FE7BC825.pdf",
  //       id:4
  //     },
  //     {
  //       icon: "file-pdf",
  //       title:"จ้าาาาา",
  //       badge: "Resolved",
  //       text: "20010 สถานีอะไรเอ่ย.pdf",
  //       time: new Date("11/06/2018"),
  //       status: "danger",
  //       link: "api/./upload/94DCEA1C-DA8C-4201-9815-7E11FE7BC825.pdf",
  //       id:5
  //     }
  //   ];
  // }

  popNotifications(index){
    this.notifications.splice(index,1)
  }

  toggelSidebar() {
    const state = this.navService.sidebarState;
    state.sidenavOpen = !state.sidenavOpen;
    state.childnavOpen = !state.childnavOpen;
  }

  signout() {
    this.auth.signout();
  }

  navigate(page) {
    this.router.navigate(['./', page]);
  }

  onConnectWs(){
    this.modalRef = this.modalService.show(EditWebserviceUserComponent, {
      width: '500px',
      backdrop: true,
      keyboard: true,
      focus: true,
      show: false,
      ignoreBackdropClick: false,
      class: 'modal-notify modal-info modal-dialog-centered',
      containerClass: '',
      animated: true,
      data: {
        data: this.user,
      }
    });
    this.modalRef.content.action.subscribe((result: any) => {
      if (result) {
        this.userService.getUserInfo().subscribe(
          (res)=>{
            this.user = res;
            this.canAccessWs = this.user.can_access_ws;
            this.userService.setUser(res);
          }
        );
      }
    });
  }

}
