import { AgGridModule } from 'ag-grid-angular';
import { BrowserModule } from '@angular/platform-browser';
import { NgModule, ErrorHandler, APP_INITIALIZER } from '@angular/core';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { SharedModule } from './shared/shared.module';
import { InMemoryWebApiModule } from 'angular-in-memory-web-api';
import { InMemoryDataService } from './shared/inmemory-db/inmemory-db.service';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { ErrorDialogComponent } from './views/others/error-dialog/error-dialog.component';
import { HttpConfigInterceptor } from './shared/intercept/httpconfig.interceptor';
import { MAT_DATE_LOCALE, MatDialogModule, MatButtonModule, MatFormFieldModule, MatInputModule, MatToolbarModule } from '@angular/material';
import { ErrorHandlerService } from './shared/services/error-handler.service';
import { ErrorDialogService } from './shared/services/errordialog.service';
import { InitDataProvider } from './shared/provider/initdata-provider';
import { GeneralConfirmDialogComponent } from './views/dialog/general-confirm-dialog/general-confirm-dialog.component';
import { MDBBootstrapModulesPro, ToastModule } from 'ng-uikit-pro-standard';
import { MDBSpinningPreloader } from 'ng-uikit-pro-standard';
import { ConfirmDeleteDialogComponent } from './views/dialog/confirm-delete-dialog/confirm-delete-dialog.component';
import { FlexLayoutModule } from '@angular/flex-layout';
import { OWL_DATE_TIME_LOCALE, OwlDateTimeIntl, OwlNativeDateTimeModule, OwlDateTimeModule } from 'ng-pick-datetime';
import { NgxPermissionsModule, NgxPermissionsService, NgxRolesService } from 'ngx-permissions';
import { UserService } from './shared/services/user.service';
import { OthersModule } from './views/others/others.module';
import { ChangePasswordDialogComponent } from './views/dialog/change-password-dialog/change-password-dialog.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { TimeColonPipe } from './shared/pipes/timecolon.pipe';
import { TimeColonDirective } from './shared/directives/timecolon.directive';
import { EditWebserviceUserComponent } from './views/dialog/edit-webservice-user/edit-webservice-user.component';
import { PrintShareDialogComponent } from './views/dialog/print-share-dialog/print-share-dialog.component';
import { ImportExceptionExcelComponent } from './views/dialog/import-exception-excel/import-exception-excel.component';
import { NgxSpinnerModule } from 'ngx-spinner';
import { UpdateImportCameramoveDialogComponent } from './views/dialog/update-import-cameramove-dialog/update-import-cameramove-dialog.component';
import { LocationMgmtAddComponent } from '../app/views/location-mgmt/location-mgmt-add/location-mgmt-add.component';
import { LocationMgmtAddCameramoveComponent } from './views/location-mgmt-cameramove/location-mgmt-add-cameramove/location-mgmt-add-cameramove.component';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
export function InitDataProviderFactory(provider: InitDataProvider) {
  return () => provider.load();
}

export function InitDataPermProviderFactory(provider: InitDataProvider) {
  return () => provider.loadPermanent();
}

export function InitUserFactory(us: UserService, ps: NgxPermissionsService, rs: NgxRolesService) {
  return () => us.load().then((data: any) => {
    // console.log("data");
    // console.log(data);
    if (data != false) {
      ps.loadPermissions(data.perm);
      rs.addRole(data.name, data.perm);
    }
  })
}

export class DefaultIntl extends OwlDateTimeIntl {
  /** A label for the cancel button */
  cancelBtnLabel = 'ยกเลิก';

  /** A label for the set button */
  setBtnLabel = 'ตกลง';

  /** A label for the range 'from' in picker info */
  rangeFromLabel = 'ตั้งแต่';

  /** A label for the range 'to' in picker info */
  rangeToLabel = 'ถึง';
};


@NgModule({
  declarations: [
    AppComponent,
    GeneralConfirmDialogComponent,
    ErrorDialogComponent,
    ConfirmDeleteDialogComponent,
    ChangePasswordDialogComponent,
    PrintShareDialogComponent,
    TimeColonPipe,
    TimeColonDirective,
    EditWebserviceUserComponent,
    PrintShareDialogComponent,
    ImportExceptionExcelComponent,
    UpdateImportCameramoveDialogComponent,
    LocationMgmtAddComponent,
    LocationMgmtAddCameramoveComponent,
  ],
  imports: [
    NgbModule,
    BrowserModule,
    MatDialogModule,
    MatButtonModule,
    MatFormFieldModule,
    MatInputModule,
    HttpClientModule,
    BrowserAnimationsModule,
    FlexLayoutModule,
    OwlDateTimeModule,
    OwlNativeDateTimeModule,
    OthersModule,
    FormsModule,
    MatAutocompleteModule,
    MatToolbarModule,
    ReactiveFormsModule,
    NgxSpinnerModule,
    AgGridModule.withComponents([]),
    ToastModule.forRoot(),
    MDBBootstrapModulesPro.forRoot(),
    InMemoryWebApiModule.forRoot(InMemoryDataService, { passThruUnknownUrl: true }),
    NgxPermissionsModule.forRoot(),
    AppRoutingModule,
    SharedModule,
  ],
  providers: [
    InitDataProvider,
    MDBSpinningPreloader,
    TimeColonPipe,
    {
      provide: APP_INITIALIZER,
      useFactory: InitDataProviderFactory,
      deps: [InitDataProvider],
      multi: true
    },
    {
      provide: APP_INITIALIZER,
      useFactory: InitDataPermProviderFactory,
      deps: [InitDataProvider],
      multi: true
    },
    {
      provide: APP_INITIALIZER,
      useFactory: InitUserFactory,
      deps: [UserService, NgxPermissionsService, NgxRolesService],
      multi: true
    },
    { provide: MAT_DATE_LOCALE, useValue: 'th-TH' },
    { provide: OWL_DATE_TIME_LOCALE, useValue: 'th-TH' },
    { provide: OwlDateTimeIntl, useClass: DefaultIntl },
    { provide: HTTP_INTERCEPTORS, useClass: HttpConfigInterceptor, multi: true },
    { provide: ErrorHandler, useClass: ErrorHandlerService },
  ],
  bootstrap: [AppComponent],
  entryComponents: [
    ErrorDialogComponent,
    GeneralConfirmDialogComponent,
    ConfirmDeleteDialogComponent,
    ChangePasswordDialogComponent,
    EditWebserviceUserComponent,
    PrintShareDialogComponent,
    ImportExceptionExcelComponent,
    UpdateImportCameramoveDialogComponent,
    LocationMgmtAddComponent,
    LocationMgmtAddCameramoveComponent,
  ],
})
export class AppModule { }
