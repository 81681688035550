import { Directive, Input, TemplateRef, ViewContainerRef, ElementRef, HostListener } from '@angular/core';
import { TimeColonPipe } from '../pipes/timecolon.pipe';

@Directive({
  selector: '[timeColon]'
})
export class TimeColonDirective {
    private el: HTMLInputElement;

    constructor(
      private elementRef: ElementRef,
      private currencyPipe: TimeColonPipe
    ) {
      this.el = this.elementRef.nativeElement;
    }
  
    ngOnInit() {
    //   this.el.value = this.currencyPipe.transform(this.el.value);
    }
  
    // @HostListener("focus", ["$event.target.value"])
    // onFocus(value) {
    //   this.el.value = this.currencyPipe.parse(value); // opossite of transform
    // }

    @HostListener("keyup", ["$event.target.value"])
    onChange(value) {
      this.el.value = this.currencyPipe.parse(value); // opossite of transform
    }

    
  
    // @HostListener("blur", ["$event.target.value"])
    // onBlur(value) {
    //   this.el.value = this.currencyPipe.transform(value);
    // }

  }