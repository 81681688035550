import { Injectable } from '@angular/core';
// import { ErrorDialogService } from '../error-dialog/errordialog.service';
import {
    HttpInterceptor,
    HttpRequest,
    HttpResponse,
    HttpHandler,
    HttpEvent,
    HttpErrorResponse
} from '@angular/common/http';

import { Observable, throwError, BehaviorSubject } from 'rxjs';
import { map, catchError, switchMap, tap, retryWhen, filter, take } from 'rxjs/operators';
import { StorageMap } from '@ngx-pwa/local-storage';
import { ErrorDialogService } from '../services/errordialog.service';
import { Router } from '@angular/router';
import { genericRetryStrategy, ShouldRetryFn } from './rxjs-utils';
import { AuthService } from '../services/auth.service';

@Injectable()
export class HttpConfigInterceptor implements HttpInterceptor {
    token:string;
    private isRefreshing = false;
    private refreshTokenSubject: BehaviorSubject<any> = new BehaviorSubject<any>(null);
    private shouldRetry: ShouldRetryFn = (error) => error.status === 500;

    constructor(private errorDialogService: ErrorDialogService, private auth: AuthService,
        protected storage:StorageMap,private router: Router) { }

    intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        const { shouldRetry } = this;
        return this.storage.get('token').pipe(tap((token:string) => this.token = token),
            switchMap((token:string) => {
                console.log('use:' + token);
                if (token) {
                    request = request.clone({ headers: request.headers.set('Authorization', 'Bearer ' + this.token) }); //no 'Bearer'
                    // request = request.clone({ headers: request.headers.set('Authorization', this.token) });
                }
                if (!request.headers.has('Content-Type')) {
                    request = request.clone({ headers: request.headers.set('Content-Type', 'application/json') });
                }else if(request.headers.get('Content-Type')=='multipart/form-data'){
                    request = request.clone({ headers: request.headers.delete('Content-Type') });
                }
                if (!request.headers.has('Accept')) {
                    request = request.clone({ headers: request.headers.set('Accept', 'application/json') });
                }
                return next.handle(request).pipe(retryWhen(genericRetryStrategy({
                    shouldRetry
                  })),map((event: HttpEvent<any>) => {
                        if (event instanceof HttpResponse) {
                            if(event.body.status != null){
                                if(event.body.status){
                                    if(event.url.includes("auth/verify") || event.url.includes("user/check_username_exist") || event.url.includes("user/check_email_exist" ) || event.url.includes("location/check_location_exist") || event.url.includes("camera/check_camera_id_exist")){
                                        return event.clone({ body: event.body.status });
                                    }else if(event.url.includes("auth/forgot_password") || event.url.includes('auth/verify_forgot_password')){
                                        return event;
                                    }else if(event.url.includes("ticket/save")){
                                        return event.clone({ body: {
                                            status : event.body.status,
                                        } });
                                    }else if(event.url.includes("ticket/print_letter") || event.url.includes("ticket/print_ticket_list") || event.url.includes("report/report1") || event.url.includes("report/report2") || event.url.includes("report/report3") || event.url.includes("report/reportweb1") || event.url.includes("report/reportweb2") || event.url.includes("report/reportweb3")){
                                        return event.clone({ body: {
                                            status : event.body.status,
                                            data : event.body.data
                                        } });
                                    }else{
                                        if(event.body.data == null){
                                            return event.clone({ body: event.body.status });
                                        }else{
                                            return event.clone({ body: event.body.data });
                                        }
                                    }
                                }else{
                                    if(event.url.includes("ticket/validate")){
                                        return event.clone({ body: event.body.error });
                                    }else if(
                                        event.url.includes("ticket/save") ||
                                        event.url.includes("ticket/print_letter") ||
                                        event.url.includes("ticket/print_ticket_list") ||
                                        event.url.includes("report/report1") ||
                                        event.url.includes("report/report2") ||
                                        event.url.includes("report/report3") ||
                                        event.url.includes("report/reportweb1") ||
                                        event.url.includes("report/reportweb2") ||
                                        event.url.includes("report/reportweb3") 
                                    ){
                                        return event.clone({ body: {
                                            status : event.body.status,
                                            error : event.body.error
                                        } });
                                    }else{
                                        // return event.clone({ body: '' });
                                        return event.clone({ body: {
                                            status : event.body.status,
                                            error : event.body.error
                                        } });
                                    }
                                }
                            }
                        }
                        return event;
                    }),
                    catchError((error: HttpErrorResponse) => {                   
                        let data = {};
                        let topic = 'แจ้งเตือน';
                        let errMessage = error.error.error;
                        if (error.status == 403) {
                            topic = "หมด session การเชื่อมต่อ";
                            errMessage = "กรุณาเข้าสู่ระบบใหม่";
                        }
                        if (error.status == 401) {
                            errMessage = "กรุณาเข้าสู่ระบบ";
                            return this.handle401Error(request, next)
                        }
                        data = {
                            reason: topic,
                            status: errMessage
                        };
                        if(error.status == 403 && token == undefined){
                            this.router.navigate(['/sessions/signin']);
                        }else{
                            console.log("catchError 400")
                            if (error.status == 403 || error.status == 401) {
                                this.router.navigate(['/sessions/signin']);
                            }else{
                                if (error.status == 400){
                                    this.errorDialogService.openDialog(data);
                                } else{
                                    window.alert(error.error.error);
                                }
                            }
                        }
                        return throwError(error);
                    })
                );
            })
        );
    }

    private processNext(next:HttpHandler , request : HttpRequest<any> , token :string)
    {
        return next.handle(this.addToken(request, token)).pipe(map((event: HttpEvent<any>) => {
            console.log("forward after refresh handle");
            if (event instanceof HttpResponse) {
                //console.log('event--->>>', event);
                if(event.body.status != null){
                    if(event.body.status){
                        if(event.url.includes("auth/verify") || event.url.includes("user/check_username_exist") || event.url.includes("user/check_email_exist" ) || event.url.includes("location/check_location_exist") || event.url.includes("camera/check_camera_id_exist")){
                            return event.clone({ body: event.body.status });
                        }else if(event.url.includes("auth/forgot_password") || event.url.includes('auth/verify_forgot_password')){
                            return event;
                        }else if(event.url.includes("ticket/save")){
                            return event.clone({ body: {
                                status : event.body.status,
                            } });
                        }else if(event.url.includes("ticket/print_letter") || event.url.includes("ticket/print_ticket_list") || event.url.includes("report/report1") || event.url.includes("report/report2") || event.url.includes("report/report3") || event.url.includes("report/reportweb1") || event.url.includes("report/reportweb2") || event.url.includes("report/reportweb3")){
                            return event.clone({ body: {
                                status : event.body.status,
                                data : event.body.data
                            } });
                        }else{
                            if(event.body.data == null){
                                return event.clone({ body: event.body.status });
                            }else{
                                return event.clone({ body: event.body.data });
                            }
                        }
                    }else{
                        if(event.url.includes("ticket/validate")){
                            return event.clone({ body: event.body.error });
                        }else if(event.url.includes("ticket/save") ||
                         event.url.includes("ticket/print_letter") ||
                         event.url.includes("ticket/print_ticket_list") ||
                         event.url.includes("report/report1") ||
                         event.url.includes("report/report2") ||
                         event.url.includes("report/report3") ||
                         event.url.includes("report/reportweb1") ||
                         event.url.includes("report/reportweb2") ||
                         event.url.includes("report/reportweb3")
                        ){
                            return event.clone({ body: {
                                status : event.body.status,
                                error : event.body.error
                            } });
                        }else{
                            return event.clone({ body: {
                                status : event.body.status,
                                error : event.body.error
                            } });
                            // return event.clone({ body: '' });
                        }
                    }
                }
            }
            return event;
        }),catchError((error: HttpErrorResponse) => {
            console.log(error)
            console.log("forward after refresh refresh catch error");
            
            return throwError(error);
        }));
    }

    private addToken(request: HttpRequest<any>, token: string) {
        return request.clone({
          setHeaders: {
            Authorization: `Bearer ${token}`
          }
        });
      }

    private handle401Error(request: HttpRequest<any>, next: HttpHandler) {
        console.log("handle 401")
        if (!this.isRefreshing) {
          this.isRefreshing = true;
          this.refreshTokenSubject.next(null);

          return this.auth.refreshToken(this.token).pipe(
            switchMap((token: any) => {
              this.isRefreshing = false;
              this.refreshTokenSubject.next(token["token"]);
              
              return this.processNext(next ,request , token["token"] )
            }) , catchError((error: HttpErrorResponse) => {
                console.log(error)
                console.log("Error when refresh / token expired");
                this.router.navigate(['/sessions/signin']);
                return throwError(error);
            }));
      
        } else {
          return this.refreshTokenSubject.pipe(
            filter(token => token != null),
            take(1),
            switchMap(token => {
                return this.processNext(next ,request , token["token"])
            }));
        }
    }
}