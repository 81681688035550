import { Injectable } from '@angular/core';
import { ConfigUrl } from "./config-url";
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { User } from '../models/user.model';
import { StorageMap } from '@ngx-pwa/local-storage';
import { Page } from '../models/page.model';
import { PagedData } from '../models/page-data.model';
import { Observable } from 'rxjs/internal/Observable';
import { BaseService } from './base.service';
import { RolePermission } from '../models/role-permission.model';
import { NgxPermissionsService, NgxRolesService } from 'ngx-permissions';
import { Subject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class UserService extends BaseService {
  private _curtrentUser: User = null;
  currentUserObs = new Subject<User>();

  constructor(
    private http: HttpClient,
    private storage: StorageMap,
    private permissionsService: NgxPermissionsService,
    private roleService: NgxRolesService
  ) {
    super();
  }

  getUserInfo() {
    return this.http.get<User>(ConfigUrl.host + '/user/info');
  }

  // getPoliceStationByUserId(userId: Page) {
  //   return this.http.get<User>(ConfigUrl.host + '/user/info');
  // }
  
  setUser(user:User){
    this.curtrentUser = user;
    this.currentUserObs.next(this.curtrentUser);
  }

  getUsers(page: Page, searchData: any): Observable<PagedData<User>> {
    let params = new HttpParams();
    params = params.append('page', page.current_page.toString());
    params = params.append('size', page.size_per_page.toString());
    params = this.setParams(params, searchData);
    return this.http.get<PagedData<User>>(ConfigUrl.host + '/user/list', { params: params });
  }

  getAllUsers(): Observable<PagedData<User>> {
    let params = new HttpParams();
    params = params.append('page', "0");
    params = params.append('size', "9999999999");
    return this.http.get<PagedData<User>>(ConfigUrl.host + '/user/list', { params: params });
  }

  addUser(user: User) {
    return this.http.post<User>(ConfigUrl.host + '/user', user);
  }

  updateUser(user: User,id:any){
    return this.http.put<User>(ConfigUrl.host + '/user/'+id, user);
  }

  updateWsUser(user: User){
    return this.http.put<User>(ConfigUrl.host + '/user/ws/'+user.id, user);
  }

  changePasswordFirst(psw: any) {
    return this.http.put<any>(ConfigUrl.host + '/user/change_password_first', psw);
  }

  changePassword(psw: any) {
    return this.http.put<any>(ConfigUrl.host + '/user/change_password', psw);
  }

  editProfile(user: any) {
    return this.http.put<User>(ConfigUrl.host + '/user/edit_profile', user);
  }

  checkExistUsername(username: string) {
    return this.http.post<any>(ConfigUrl.host + '/user/check_username_exist', { 'USER': username });
  }

  checkExistEmail(email: string) {
    return this.http.post<any>(ConfigUrl.host + '/user/check_email_exist', { 'email': email });
  }

  myStation(){
    return this.http.get<any>(ConfigUrl.host + '/user/mystation');
  }

  changeStation(id:any){
    return this.http.get<any>(ConfigUrl.host + '/user/change_station/'+id);
  }

  getAllUserByPermission(permissionName:any){
    let params = new HttpParams();
    params = params.append('page', "0");
    params = params.append('size', "9999999999");
    params = params.append('permissionId',this.getPermissionId(permissionName).toString())
    return this.http.get<PagedData<User>>(ConfigUrl.host + '/common/user_by_permission', { params: params });
  }

  public load() {
    return new Promise((resolve, reject) => {
      this.storage.get('token').subscribe(
        (token) => {
          if (token != undefined) {
            this.http.get<User>(ConfigUrl.host + '/user/info').subscribe(
              (res: User) => {
                if(res){
                this.curtrentUser = res;
                var userPerm = [];
                this.curtrentUser.role.permission.forEach(
                  (rolePermission: RolePermission) => {
                    var permName = this.mapPermission(rolePermission.id);
                    if (permName != null) {
                      //userPerm.push(permName);
                      if (rolePermission.level != 0) {
                        userPerm.push(permName);
                        userPerm.push(permName + "_" + rolePermission.level);
                      }
                      //userPerm[permName] = rolePermission.id;
                    }
                  }
                )
                resolve({ name: this.curtrentUser.role.name, perm: userPerm });
                //resolve({name: this.curtrentUser.role.name, perm: this.curtrentUser.role.permission});
              }else{
                //wrong user or something else, force to login again
                this.storage.clear().subscribe(
                  ()=>{
                    resolve(false);
                  }
                );
              }
            }, error=>{
                resolve(false);
              })
            
            //resolve(false);
          }else {
            resolve(false);
          }
        }
      )
    })
  }

  loadLogin() {
    return new Promise((resolve, reject) => {
      this.http.get<User>(ConfigUrl.host + '/user/info').subscribe(
        (res: User) => {
          console.log("is loadLogin : ")
          console.log(res)
            this.curtrentUser = res;
            var userPerm = [];
            this.curtrentUser.role.permission.forEach(
              (rolePermission: RolePermission) => {
                var permName = this.mapPermission(rolePermission.id);
                if (permName != null) {
                  //userPerm.push(permName);
                  if (rolePermission.level != 0) {
                    userPerm.push(permName);
                    userPerm.push(permName + "_" + rolePermission.level);
                  }
                }
              }
            )
            resolve({ name: this.curtrentUser.role.name, perm: userPerm });
          }, error=>{
            resolve(false);
        }
      )
    })
  }


  /**
   * Getter curtrentUser
   * @return {User }
   */
  public get curtrentUser(): User {
    return this._curtrentUser;
  }

  /**
   * Setter curtrentUser
   * @param {User } value
   */
  public set curtrentUser(value: User) {
    this._curtrentUser = value;
  }
}
