import { Component, OnInit } from '@angular/core';
import { Subject } from 'rxjs';
import { MDBModalRef } from 'ng-uikit-pro-standard';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { User } from '../../../shared/models/user.model';
import { BaseComponent } from '../../../shared/components/base-component';
import { NgxPermissionsService } from 'ngx-permissions';
import { InitDataProvider } from '../../../shared/provider/initdata-provider';
import { UserService } from '../../../shared/services/user.service';
import { TicketService } from '../../../shared/services/ticket.service';

@Component({
  selector: 'app-edit-webservice-user',
  templateUrl: './edit-webservice-user.component.html',
  styleUrls: ['./edit-webservice-user.component.scss']
})
export class EditWebserviceUserComponent extends BaseComponent implements OnInit {
  dataForm: FormGroup;
  action: Subject<any> = new Subject();
  data: User;
  isSubmit:boolean = false;
  canAccessWs:boolean;
  
  constructor(public modalRef: MDBModalRef,
    private userServe:UserService,
    ngxPermissionServ: NgxPermissionsService,
    public initData: InitDataProvider,
    public userServ: UserService
  ) {
    super(ngxPermissionServ, initData, userServ); 
   }

  ngOnInit() {
    this.canAccessWs = this.data.can_access_ws;
    this.dataForm = new FormGroup({
      'id': new FormControl(this.data.id, Validators.required),
      'ws_username': new FormControl(this.data.ws_username, Validators.required),
      'ws_password': new FormControl(this.data.ws_password, Validators.required),
      // 'role_id': new FormControl(this.data.role.id, Validators.required),
    })
  }

  onSubmit(){
    if(this.dataForm.valid){
      this.isSubmit = true;
      this.userServe.updateWsUser(this.dataForm.value).subscribe(
        (res)=>{
          if(res){
            this.userServ.getUserInfo().subscribe(
              (resInfo)=>{
                this.isSubmit = false;
                this.canAccessWs = resInfo.can_access_ws;
              }
            );
          }
        }
      )
    }else{
      this.validateAllFormFields(this.dataForm);
    }
  }

  onClose(){
    this.action.next(true);
    this.modalRef.hide();
  }

}
