import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { AuthLayoutComponent } from './shared/components/layouts/auth-layout/auth-layout.component';
import { AuthGaurd } from './shared/services/auth.gaurd';
import { BlankLayoutComponent } from './shared/components/layouts/blank-layout/blank-layout.component';
import { AdminLayoutSidebarCompactComponent } from './shared/components/layouts/admin-layout-sidebar-compact/admin-layout-sidebar-compact.component';
import { AdminLayoutSidebarLargeComponent } from './shared/components/layouts/admin-layout-sidebar-large/admin-layout-sidebar-large.component';
import { NgxPermissionsGuard } from 'ngx-permissions';
import { NotFoundComponent } from './views/others/not-found/not-found.component';
import { FirstLoginGaurd } from './shared/services/first-login.gaurd.';

const adminRoutes: Routes = [
  {
    path: '',
    loadChildren: () => import('./views/home/home.module').then(m => m.HomeModule)
  },
  {
    path: 'search_incident',
    loadChildren: () => import('./views/incident/incident.module').then(m => m.IncidentModule),
    canActivate: [NgxPermissionsGuard],
    data: {
      permissions: {
        only: "ViewIncidentImport",
        redirectTo: '/403'
      }
    }
  },
  {
    path: 'camera_move',
    loadChildren: () => import('./views/cameramove/cameramove.module').then(m => m.CameramoveModule),
    canActivate: [NgxPermissionsGuard],
    data: {
      permissions: {
        only: "ViewIncidentCameramoveImport",
        redirectTo: '/403'
      }
    }
  },
  {
    path: 'dashboard',
    loadChildren: () => import('./views/dashboard/dashboard.module').then(m => m.DashboardModule),
  },
  {
    path: 'report',
    loadChildren: () => import('./views/report/report.module').then(m => m.ReportModule),
  },
  {
    path: 'user',
    loadChildren: () => import('./views/user/user.module').then(m => m.UserModule),
    canActivate: [NgxPermissionsGuard],
    data: {
      permissions: {
        only: "ViewUser",
        redirectTo: '/403'
      }
    }
  },
  {
    path: 'station_mgmt',
    loadChildren: () => import('./views/station-mgmt/station-mgmt.module').then(m => m.StationMgmtModule),
    canActivate: [NgxPermissionsGuard],
    data: {
      permissions: {
        only: "PoliceStationMgt",
        redirectTo: '/403'
      }
    }
  },
  {
    path: 'exception_car',
    loadChildren: () => import('./views/exception-car/exception-car.module').then(m => m.ExceptionCarModule),
    canActivate: [NgxPermissionsGuard],
    data: {
      permissions: {
        only: ['ExceptionCentralMgt_3'],
        redirectTo: '/403'
      }
    }
  },
  {
    path: 'exception_car_station',
    loadChildren: () => import('./views/exception-car-station/exception-car-station.module').then(m => m.ExceptionCarStationModule),
    canActivate: [NgxPermissionsGuard],
    data: {
      permissions: {
        only: ['ExceptionStationMgt'],
        redirectTo: '/403'
      }
    }
  },
  {
    path: 'camera_info',
    loadChildren: () => import('./views/camera-info/camera-info.module').then(m => m.CameraInfoModule),
    canActivate: [NgxPermissionsGuard],
    data: {
      permissions: {
        only: "CameraMgt",
        redirectTo: '/403'
      }
    }
  },
  {
    path: 'location_mgmt',
    loadChildren: () => import('./views/location-mgmt/location-mgmt.module').then(m => m.LocationMgmtModule),
    canActivate: [NgxPermissionsGuard],
    data: {
      permissions: {
        only: "LocationMgt",
        redirectTo: '/403'
      }
    }
  }, 
  {
    path: 'provincial_police_station',
    loadChildren: () => import('./views/provincial-police/provincial-police.module').then(m => m.ProvincialPoliceModule),
    canActivate: [NgxPermissionsGuard],
    data: {
      permissions: {
        only: "LocationMgt",
        redirectTo: '/403'
      }
    }
  },
  {
    path: 'policeofficer_mgmt',
    loadChildren: () => import('./views/police-officers/police-officers.module').then(m => m.PoliceOfficersModule),
    canActivate: [NgxPermissionsGuard],
    data: {
      permissions: {
        only: "OfficerMgt",
        redirectTo: '/403'
      }
    }
  },
  {
    path: 'permission_mgmt',
    loadChildren: () => import('./views/permission-mgmt/permission-mgmt.module').then(m => m.PermissionMgmtModule),
    canActivate: [NgxPermissionsGuard],
    data: {
      permissions: {
        only: "ผู้ดูแลระบบ",
        redirectTo: '/403'
      }
    }
  },
  {
    path: 'config_res',
    loadChildren: () => import('./views/config-res/config-res.module').then(m => m.ConfigResModule),
    canActivate: [NgxPermissionsGuard],
    data: {
      permissions: {
        only: "ผู้ดูแลระบบ",
        redirectTo: '/403'
      }
    }
  },
  {
    path: 'config_camera_pic',
    loadChildren: () => import('./views/config-camera-pic/config-camera-pic.module').then(m => m.ConfigCameraPicModule),
    canActivate: [NgxPermissionsGuard],
    data: {
      permissions: {
        only: "ผู้ดูแลระบบ",
        redirectTo: '/403'
      }
    }
  },
  {
    path: 'accuse',
    loadChildren: () => import('./views/accuse/accuse.module').then(m => m.AccuseModule),
    canActivate: [NgxPermissionsGuard],
    data: {
      permissions: {
        only: "ผู้ดูแลระบบ",
        redirectTo: '/403'
      }
    }
  },
  {
    path: 'config_offender',
    loadChildren: () => import('./views/config-offender/config-offender.module').then(m => m.ConfigOffenderModule),
    canActivate: [NgxPermissionsGuard],
    data: {
      permissions: {
        only: "ผู้ดูแลระบบ",
        redirectTo: '/403'
      }
    }
  },
  {
    path: 'account',
    loadChildren: () => import('./views/account/account.module').then(m => m.AccountModule),
    canActivate: [NgxPermissionsGuard],
  },{
    path: 'password',
    loadChildren: () => import('./views/setting-password/setting-password.module').then(m => m.SettingPasswordModule),
    canActivate: [NgxPermissionsGuard],
  },{
    path: 'search_ticket',
    canActivate: [NgxPermissionsGuard],
    loadChildren: () => import('./views/ticket-search/ticket-search.module').then(m => m.TicketSearchModule),
    data: {
      permissions: {
        only: "ViewSearchTicket",
        redirectTo: '/403'
      }
    },
  },{
    path: 'portal_ear',
    canActivate: [NgxPermissionsGuard],
    loadChildren: () => import('./views/portal-ear/portal-ear.module').then(m => m.PortalEARModule),
    data: {
      permissions: {
        only: "ViewSearchTicket",
        redirectTo: '/403'
      }
    },
  },
  {
    path: 'warning_ticket',
    loadChildren: () => import('./views/ticket-warning/ticket-warning.module').then(m => m.TicketWarningModule),
    canActivate: [NgxPermissionsGuard],
    data: {
      permissions: {
        only: "WariningTicket",
        redirectTo: '/403'
      }
    }
  },
  {
    path: 'about',
    loadChildren: () => import('./views/about/about.module').then(m => m.UserModule),
    canActivate: [NgxPermissionsGuard],
    // data: {
    //   permissions: {
    //     only: "ViewUser",
    //     redirectTo: '/403'
    //   }
    // }
  },
  {
    path: 'verify_license',
    loadChildren: () => import('./views/verify-license/verify-license.module').then(m => m.VerifyLicenseModule),
    canActivate: [NgxPermissionsGuard],
  },
];

const routes: Routes = [
  {
    path: '',
    component: AdminLayoutSidebarCompactComponent,
    canActivate: [AuthGaurd],
    children: adminRoutes
  },
  {
    path: '',
    component: AuthLayoutComponent,
    children: [
      {
        path: 'sessions',
        loadChildren: () => import('./views/sessions/sessions.module').then(m => m.SessionsModule)
      }
    ]
  },
  {
    path: 'approve_ticket',
    component: BlankLayoutComponent,
    canActivate: [AuthGaurd, NgxPermissionsGuard],
    data: {
      permissions: {
        only: ["ViewTicket","AddTicket","ViewCameramoveTicket","AddCameramoveTicket"],
        redirectTo: '/403'
      }
    },
    loadChildren: () => import('./views/ticket/ticket.module').then(m => m.TicketModule)
  },
  {
    path: '403',
    loadChildren: () => import('./views/others/others.module').then(m => m.OthersModule)
  },
  {
    path: '**',
    component: NotFoundComponent,
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { useHash: true, scrollPositionRestoration: 'enabled' })],
  exports: [RouterModule]
})
export class AppRoutingModule { }
