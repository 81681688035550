import { Component, OnInit, EventEmitter, ChangeDetectorRef } from '@angular/core';
import { MDBModalRef, UploadOutput, UploadFile, UploadInput } from 'ng-uikit-pro-standard';
import { humanizeBytes } from 'ng-uikit-pro-standard';
import { FileManagementService } from '../../../shared/services/fileManagement.service';
import { FormGroup, FormControl, Validators, AbstractControl } from '@angular/forms';
import { BaseComponent } from '../../../shared/components/base-component';
import { NgxPermissionsService } from 'ngx-permissions';
import { InitDataProvider } from '../../../shared/provider/initdata-provider';
import { UserService } from '../../../shared/services/user.service';
import { PoliceStation } from '../../../shared/models/police-station.model';
import { WhitelistLicense } from '../../../shared/models/whitelist-license.model';
import { WhitelistOwner } from '../../../shared/models/whitelist-owner.model';
import { ExceptionName } from '../../../shared/models/exception-name.model';
import { BlacklistLicense } from '../../../shared/models/blacklist-license.model';
import { UploaderOptions } from 'ng-uikit-pro-standard/lib/pro/file-input/classes/mdb-uploader.class';
import { CompileShallowModuleMetadata } from '@angular/compiler';

@Component({
  selector: 'app-import-exception-excel',
  templateUrl: './import-exception-excel.component.html',
  styleUrls: ['./import-exception-excel.component.scss']
})
export class ImportExceptionExcelComponent extends BaseComponent implements OnInit {
  files: UploadFile[];
  filename: string = "";
  file_test : File;
  dragOver: boolean;
  filteredPoliceStation: Array<any>;
  uploadInput: EventEmitter<UploadInput>;
  humanizeBytes: Function;
  dataForm: FormGroup;
  isSubmit: boolean = false;
  isResult: boolean = false;
  dataResult: UploadExcel = null;
  options: UploaderOptions;
  isCentral: boolean;

  constructor(public modalRef: MDBModalRef,
    private fileMgmt: FileManagementService,
    private cd: ChangeDetectorRef,
    ngxPermissionServ: NgxPermissionsService,
    public initData: InitDataProvider,
    public userServ: UserService
  ) {
    super(ngxPermissionServ, initData, userServ);
    this.options = {
      concurrency: 1,
      maxUploads: 1,
      allowedContentTypes: ['application/vnd.ms-excel.sheet.macroEnabled.12']
    };
    this.files = [];
    this.uploadInput = new EventEmitter<UploadInput>();
    this.humanizeBytes = humanizeBytes;
  }

  ngOnInit() {
    if (this.hasPermission("ExceptionStationMgt_1")) {
      this.filteredPoliceStation = this.initData.policeStation.filter(x => x.id == this.userServ.curtrentUser.police_station_id).map(
        (station: PoliceStation) => {
          return { value: station.id, label: station.thai_short_name };
        }
      )
    } else if (this.hasPermission("ExceptionStationMgt_3")) {
      this.filteredPoliceStation = this.initData.policeStation.map(
        (station: PoliceStation) => {
          return { value: station.id, label: station.thai_short_name };
        }
      )
    }
    this.dataForm = new FormGroup({
      'police_station_id': new FormControl(null, (this.isCentral)?Validators.nullValidator:Validators.required),
      'file': new FormControl(null, [Validators.required, this.ValidateUrl]),
    })
  }

  onUploadOutput(event: UploadOutput | any): void {
    if (event.file != undefined) {
      const file: File = event.file.nativeFile;
          this.filename = file.name;
          this.file_test = file;
          this.dataForm.patchValue({
            file: file
          });
          var reader = new FileReader();
          reader.readAsDataURL(file);
          reader.onload = (_event) => {
            this.cd.markForCheck();
          }
    }
  }

  onSubmit() {
    if (this.dataForm.valid) {
      this.isSubmit = true;
      this.fileMgmt.uploadExceptionCarExcel(this.file_test,this.dataForm.value.police_station_id).subscribe(
        (res: UploadExcel) => {
          this.isSubmit = false;
          this.isResult = true;
          this.dataResult = res;
        },
        (err) => {
          this.isSubmit = false;
          this.modalRef.hide();
        })
    } else {
      this.validateAllFormFields(this.dataForm);
    }
  }

  ValidateUrl(control: AbstractControl) {
    return null;
    if(control.value){
      var contentType = (control.value).split(",")[0].split(":")[1].split(";")[0];
      if(contentType != "application/vnd.ms-excel.sheet.macroEnabled.12"){
        console.log("type miss match");
        return { validFile: true  };
      }
    }
    return null;
  }


}


export class UploadExcel {
  private _whitelist_license: WhitelistLicense[] = [];
  private _whitelist_owner: WhitelistOwner[] = [];
  private _exception_name: ExceptionName[] = [];
  private _blacklist_license: BlacklistLicense[] = [];
}