import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { ConfigUrl } from './config-url';
import { BaseService } from './base.service';
import { Header } from 'src/app//shared/models/header.model';
@Injectable({
  providedIn: 'root'
})
export class HeaderService extends BaseService {

  constructor(
    private http: HttpClient
  ) {
    super();
  }

//   findIncidentAmount(searchVal: any) {
//     let params = new HttpParams();
//         params = this.setParams(params, searchVal);
//     return this.http.get<{ total_item: number, vid: string, min_passtime:Date, max_passtime:Date  }>(ConfigUrl.host + '/incident/list', {params});
//   }

  countTicketByUser(id: any) {
    // return this.http.get<Header{}>(ConfigUrl.host + '/ticket/count_by_user');
    // return this.http.get<any>(ConfigUrl.host + '/location/check_location_exist/'+locationId, { });
    return this.http.get<any>(ConfigUrl.host + '/ticket/count_by_user/'+id)
  }

}
