import { Injectable } from "@angular/core";
import { BaseService } from "./base.service";
import { HttpClient, HttpParams, HttpHeaders } from "@angular/common/http";
import { ImageResolution } from "../models/image-resolution.model";
import { Page } from "../models/page.model";
import { PagedData } from "../models/page-data.model";
import { ConfigUrl } from "./config-url";
import { Observable } from "rxjs/internal/Observable";
import { UploadFile } from "ng-uikit-pro-standard";

@Injectable({
  providedIn: 'root'
})
export class FileManagementService extends BaseService {

  constructor(
    private http: HttpClient
  ) {
    super();
  }

  public uploadExceptionCarExcel(exceptionFile: File,police_station_id :any) {
    // //const formData = new FormData();
    // let headers = new HttpHeaders();
    // //formData.set('contactxlsx', exceptionFile.);
    // headers = headers.set('Content-Type', 'multipart/form-data');
    console.log("exceptionFile : ")
    console.log(exceptionFile)

    let formData = new FormData();
    if (exceptionFile != null && exceptionFile != undefined) {
      formData.set('file', exceptionFile);
    }
    if (police_station_id != null && police_station_id != undefined) {
      formData.append('police_station_id', police_station_id);
    }
    let headers = new HttpHeaders().set('Content-Type', "multipart/form-data").set('Accept', 'application/json');
    return this.http.post<any>(ConfigUrl.host + '/exceptioncarexcel/importdata?police_station_id='+police_station_id, formData,{headers});
  }

}