import { Component, OnInit, Inject } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { DatePipe } from '@angular/common';
@Component({
  selector: 'app-update-import-cameramove-dialog',
  templateUrl: './update-import-cameramove-dialog.component.html',
  styleUrls: ['./update-import-cameramove-dialog.component.scss']
})
export class UpdateImportCameramoveDialogComponent implements OnInit {

  constructor(
    public dialogRef: MatDialogRef<UpdateImportCameramoveDialogComponent>,
    private datePipe: DatePipe,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) { }
      
  onNoClick(): void {
  this.dialogRef.close();
  }

  ngOnInit() {
  }

  onTimeChangeStart(event: any) {
    if (event != null) {
      var startDate = this.datePipe.transform(new Date(event), 'yyyy-MM-dd HH:mm:ss');
      event = startDate
    }
  }

}
