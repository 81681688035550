import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams  } from '@angular/common/http';
import { Location } from '../models/location.model';
import { ConfigUrl } from "./config-url";
import { Page } from "../models/page.model";
import { Observable } from "rxjs";
import { PagedData } from "../models/page-data.model";
import { BaseService } from './base.service';
@Injectable({
  providedIn: 'root'
})
export class LocationService extends BaseService {
  constructor(private http: HttpClient) {
    super();
   }
  getLocation(page: Page, searchData: any): Observable<PagedData<Location>> {
      let params = new HttpParams();
      params = params.append('page', page.current_page.toString());
      params = params.append('size', page.size_per_page.toString());
      params = this.setParams(params, searchData);
	    return this.http.get<PagedData<Location>>(ConfigUrl.host+'/location',{ params: params });
  }

  getLocationAll(){
    return this.http.get<any>(ConfigUrl.host + '/location/allLocation', { });
  }
  
  save(data : any){
    data = this.removeNullBody(data);
    return this.http.post<any>(ConfigUrl.host + '/location', data)
  }

  update(data : any,id:any){
    data = this.removeNullBody(data);
    return this.http.put<any>(ConfigUrl.host + '/location/'+id, data)
  }

  delete(id: number){
    return this.http.delete<any>(ConfigUrl.host+'/location/'+id, {});
  }
  
  checkExistLocation(locationId: string) {
    return this.http.get<any>(ConfigUrl.host + '/location/check_location_exist/'+locationId, { });
  }

  getProvincialPolice() {
    let params = new HttpParams();
    params = params.append('page', "0");
    params = params.append('size', "9999999999");
    return this.http.get<any>(ConfigUrl.host + '/provincial_police_station',{ params: params });
}

}
