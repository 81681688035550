import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { NotFoundComponent } from './not-found/not-found.component';
import { NoPermissionComponent } from './no-permission/no-permission.component';

const routes: Routes = [
    {
        path: '404',
        component: NotFoundComponent
    },
    {
        path: '403',
        component: NoPermissionComponent
    }
];

@NgModule({
    imports: [RouterModule.forChild(routes)],
    exports: [RouterModule]
})
export class OthersRoutingModule { }
