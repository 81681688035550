import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef } from '@angular/material';
import {MAT_DIALOG_DATA} from '@angular/material'

@Component({
  selector: 'kt-general-confirm-dialog',
  templateUrl: './general-confirm-dialog.component.html',
  styleUrls: ['./general-confirm-dialog.component.scss']
})

export class GeneralConfirmDialogComponent implements OnInit {
  message: string;
  submitTxt: string;
  titletxt: string;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    private dialogRef: MatDialogRef<GeneralConfirmDialogComponent>
  ) {
    this.titletxt = "คุณต้องการบันทึกหรือไม่";
   }

  ngOnInit() {
    this.message = this.data.message;
    this.submitTxt = this.data.submitTxt;
    if(this.data.titletxt != undefined){
      this.titletxt = this.data.titletxt;
    }
  }

  onNext(){
    this.dialogRef.close(true);//save data
  }

  onNoClick() {
    this.dialogRef.close(false);//cancel
  }

}
