import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { User } from '../models/user.model';
import { Role } from '../models/role.model';
import { ProvinceTransport } from '../models/province-transport.model';
import { ConfigUrl } from './config-url';

@Injectable({
  providedIn: 'root'
})
export class ProvinceTransportService {

  constructor(
    private http: HttpClient
  ) { }

  getProvinces() {
    return this.http.get<ProvinceTransport[]>(ConfigUrl.host+'/ProvinceTransport/list');
  }

}
