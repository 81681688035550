import { Injectable } from '@angular/core';
import { CanActivate, Router, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { AuthService } from './auth.service';
import { StorageMap } from '@ngx-pwa/local-storage';
import { map } from 'rxjs/operators';
import { UserService } from './user.service';
import { MDBModalRef, MDBModalService } from 'ng-uikit-pro-standard';
import { ChangePasswordDialogComponent } from '../../views/dialog/change-password-dialog/change-password-dialog.component';

@Injectable({
  providedIn: 'root'
})
export class AuthGaurd implements CanActivate {
  modalRef: MDBModalRef;
  constructor(
    private router: Router,
    private authServ:AuthService, 
    private userServ: UserService,
    private modalService: MDBModalService,
    private storage: StorageMap
  ) { }

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    //check token here
    return this.authServ.isAuthorized().pipe(map(
      (res)=>{
        // console.log("res guard");
        // console.log(res);
        if(res){
          var curresntUser = this.userServ.curtrentUser;
          //console.log(curresntUser);
          if(curresntUser != null){
            if(curresntUser.is_firsttime == 1){
              //open change password dialog
              //console.log("open dialog");
              this.modalRef = this.modalService.show(ChangePasswordDialogComponent, {
                width: '850px',
                backdrop: true,
                keyboard: true,
                focus: true,
                show: false,
                ignoreBackdropClick: true,
                class: 'modal-lg modal-notify modal-warning modal-dialog-centered',
                containerClass: '',
                animated: true,
              });
            }
          }
          return true;
        }else{
          this.storage.clear().subscribe(
            (res)=>{
              this.router.navigate(['/sessions/signin']);
              return false;      
            }
        );   
        }
      }
    ));
    //return true;
  }
}
