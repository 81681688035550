import { Component, OnInit } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { CustomValidators } from 'ng2-validation';
import { UserService } from '../../../shared/services/user.service';
import { BaseComponent } from '../../../shared/components/base-component';
import { ToastService, MDBModalRef } from 'ng-uikit-pro-standard';
import { NgxPermissionsService } from 'ngx-permissions';
import { InitDataProvider } from '../../../shared/provider/initdata-provider';
import { User } from '../../../shared/models/user.model';

@Component({
  selector: 'app-change-password-dialog',
  templateUrl: './change-password-dialog.component.html',
  styleUrls: ['./change-password-dialog.component.scss']
})
export class ChangePasswordDialogComponent extends BaseComponent implements OnInit {
  dataForm: FormGroup;
  isSubmit: boolean = false;
  isNameExist: boolean = null;
  isCheckingName: boolean = false;

  constructor(
    public modalRef: MDBModalRef,
    private toastrService: ToastService,
    ngxPermissionServ: NgxPermissionsService,   
    public initData: InitDataProvider,
    public userServ: UserService
  ) {
    super(ngxPermissionServ, initData, userServ);
  }

  ngOnInit() {
    const user = this.userServ.curtrentUser;
    const newpassword = new FormControl(null, [Validators.required, Validators.minLength(8), Validators.maxLength(16)]);
    const newpassword2 = new FormControl(null, [Validators.required, CustomValidators.equalTo(newpassword), Validators.minLength(8), Validators.maxLength(16)]);
    this.dataForm = new FormGroup({
      'email': new FormControl(user.email, [Validators.required, Validators.email]),
      'old_password': new FormControl(this.defaultPassword),
      'password': newpassword,
      'confirmpassword': newpassword2,
      'is_firsttime': new FormControl(0),
    });
  }

  onSubmit() {
    if (this.dataForm.valid) {
      this.isSubmit = true;
      this.userServ.changePasswordFirst({'email': this.dataForm.value['email'],'password': this.dataForm.value['password'],'is_firsttime': this.dataForm.value['is_firsttime']}).subscribe(
        (res) => {
          if (res.status) {
            this.toastrService.success('การบันทึกสำเร็จ!', '', { opacity: this.toastOpacity });
                    this.isSubmit = false;
                    this.modalRef.hide();
          } else {
            this.isSubmit = false;
            this.toastrService.error('การบันทึกล้มเหลว!', '', { opacity: this.toastOpacity });
          }
        });
    } else {
      this.validateAllFormFields(this.dataForm);
    }
  }

  checkExistEmail(email: string) {
    if (email.length > 0 && this.dataForm.get('email').valid) {
      this.isCheckingName = true;
      this.userServ.checkExistEmail(email).subscribe(
        (res: boolean) => {
          if(!res){
            this.dataForm.get('email').setErrors({'duplicateEmail': true});
          }else{
            this.dataForm.get('email').setErrors(null);
          }
          this.isNameExist = res;
          this.isCheckingName = false;
        }
      );
    } else {
      this.isNameExist = null;
      this.isCheckingName = false;
    }
  }

}
