import { Injectable } from "@angular/core";
import { LocalStoreService } from "./local-store.service";
import { Router } from "@angular/router";
import { of, Subject } from "rxjs";
import { delay, tap } from "rxjs/operators";
import { ConfigUrl } from "./config-url";
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { StorageMap } from "@ngx-pwa/local-storage";
import { User } from "../models/user.model";
import * as jwt_decode from 'jwt-decode';
import { NgxPermissionsService, NgxRolesService } from "ngx-permissions";

@Injectable({
  providedIn: "root"
})
export class AuthService {
  //Only for demo purpose
  authenticated = true;
  isLogin = new Subject<boolean>();
  private _currentUser: User = null;
  constructor(
    private store: LocalStoreService,
    private storage: StorageMap,
    private router: Router,
    private http: HttpClient,
    private ps:NgxPermissionsService, 
    private rs: NgxRolesService,
  ) {
    //this.checkAuth();
  }

  checkAuth() {
    // this.authenticated = this.store.getItem("demo_login_status");
  }

  getuser() {
    return of({});
  }

  isAuthorized() {
    this.http.post<any>(ConfigUrl.host + '/auth/verify', {}).subscribe((
      (res) => {
        this.setCurrentUserByToken().then(
          () => {
            this.isLogin.next(res);
          }
        );
      }
    ));
    return this.isLogin.asObservable();
  }

  refreshToken(token : string) {
    let refreshToken = localStorage.getItem('refreshToken')
    return this.http.post<any>(`${ConfigUrl.host}/auth/refresh`, {
      'access_token': token, "refresh_token" : refreshToken
    }).pipe(tap((res) => {
      this.storage.set("token", res["token"]).subscribe(
        (res) => {
          console.log("create new token success")
         })
        localStorage.setItem("refreshToken", res["refresh_token"])
    }) ) ;
  }

  // brefreshToken(){
  //   console.log('refresh')
  //   return new Promise((resolve, reject) => {
  //     this.http.post<any>(ConfigUrl.host + '/auth/refresh', {}).subscribe((
  //       (res) => {
  //         console.log('new token: ' +res['refreshToken'])
  //         this.storage.set('token' , res['refreshToken']).subscribe(
  //           (res) => {
  //             console.log(res)
  //             resolve(true);
  //           }
  //         )
  //       }
  //     ));
  //   })
  // }

  signin(username: string, password: string) {
    return this.http.post<any>(ConfigUrl.host + '/auth/login', { username, password })
  }

  signout() {
    this.ps.flushPermissions();
    this.rs.flushRoles();
    //clear all storage
    
    this.storage.clear().subscribe(() => {
      this.authenticated = false;
      this.store.setItem("demo_login_status", false);
      this.router.navigateByUrl("/sessions/signin");
    });
  }

  private setCurrentUserByToken() {
    //check if currentUser is Not null
    return new Promise((resolve, reject) => {
      this.storage.get('token').subscribe(
        (token) => {
          var decodedUser = jwt_decode(token);
          if(this.currentUser == null){
            this.http.get<User>(ConfigUrl.host + '/user/info', {}).subscribe(
              (res:User)=>{
                this.currentUser = res;
                this.storage.set("currentUser", this.currentUser).subscribe(
                  ()=>{
                    resolve(true);
                  }
                )
              }
            )
          }else if(this.currentUser.id != decodedUser['userId']){
            this.http.get<User>(ConfigUrl.host + '/user/info', {}).subscribe(
              (res:User)=>{
                this.currentUser = res;
                this.storage.set("currentUser", this.currentUser).subscribe(
                  ()=>{
                    resolve(true);
                  }
                )
              }
            )
          }
          resolve(true);
        }
      )
    })
  }

  forgotPassword(emailForm:any){
    return this.http.post<any>(ConfigUrl.host + '/auth/forgot_password', emailForm);
  }

  resetPassword(newPassForm:any){
    return this.http.post<any>(ConfigUrl.host + '/auth/verify_forgot_password', newPassForm);
  }

  /**
   * Getter currentUser
   * @return {User }
   */
  public get currentUser(): User {
    return this._currentUser;
  }

  /**
   * Setter currentUser
   * @param {User } value
   */
  public set currentUser(value: User) {
    this._currentUser = value;
  }


}
