import { FormGroup, FormControl, FormBuilder } from "@angular/forms";
import { NgxPermissionsObject, NgxPermissionsService } from "ngx-permissions";
import { PoliceStation } from "../models/police-station.model";
import { Location } from "../models/location.model";
import { InitDataProvider } from "../provider/initdata-provider";
import { UserService } from "../services/user.service";
import { CameraInfo } from "../models/camera-info.model";

export class BaseComponent{
  readonly toastOpacity: number = 1; //for change later
  readonly titleMessage: string = "สถานะ";
  readonly saveSuccessMsg: string = "บันทึกข้อมูลสำเร็จ!";
  readonly saveErrorMsg: string = "การบันทึกข้อมูลล้มเหลว!";
  readonly defaultPassword: string = "Passw0rd!";
  readonly maxNum: number = 999999;
  readonly noPermission: string = "_0";
  readonly ownStationLevel: string = "_1";
  readonly ownOnlyLevel: string = "_2";
  readonly allLevel: string = "_3";
  readonly carStatus = {
    "A": "ปกติ",
    "T": "เปลี่ยนประเภท",
    "O": "ย้ายออก",
    "C": "ยกเลิกเลขทะเบียน มาจากโปรแกรมยกเลิกจดทะเบียน",
    "TP": "แจ้งไม่ใช้ชั่วคราว",
    "PM": "แจ้งไม่ใช้ตลอดไป",
    "EX": "ออกนอกราชอาณาจักรตลอดไป",
    "S": "ระงับใช้รถ(ค้างชำระภาษีเกิน3ปี)",
    "H": "อำพราง",
    "CH": "เปลี่ยน พ.ร.บ. โดยผลทางกฎหมาย"
  }
  readonly ticketStatusDesc = {
    "01": "ยังไม่ตรวจสอบ",
    "02": "ผ่าน",
    "03": "ยกเลิก",
    "04": "ยังไม่ชำระค่าปรับ",
    "05": "ใบสั่งหมดอายุความ",
    "06": "ชำระค่าปรับแล้ว",
    "07": "ไม่ผ่าน",
  }

  readonly ticketStatusFilter = {
    "01": "ข้อมูลยังไม่ได้ตรวจสอบ",
    "02": "ข้อมูลตรวจสอบแล้ว",
    "03": "ใบสั่งถูกยกเลิก",
    "04": "ใบสั่งยังไม่ชำระค่าปรับ",
    "05": "ใบสั่งหมดอายุความ",
    "06": "ใบสั่งชำระค่าปรับแล้ว",
    "07": "ข้อมูลไม่ผ่าน",
  }

  readonly payChannel = {
    "01": "STATION",
    "02": "KTB",
    "03": "BANKINGAGENT",
    "00": "N/A",
  }

  readonly ownerType = {
    0: "บุคคลธรรมดา",
    1: "นิติบุคคล",
  }


  constructor(public ngxPermissionServ: NgxPermissionsService, public initData: InitDataProvider, public userServ: UserService) {
  }


  validateAllFormFields(formGroup: FormGroup) {
    Object.keys(formGroup.controls).forEach(field => {
      const control = formGroup.get(field);
      if (control instanceof FormControl) {
        control.markAsTouched({ onlySelf: true });
      } else if (control instanceof FormGroup) {
        this.validateAllFormFields(control);
      }
    });
  }

  validateAllFormArrays(formGroup: FormGroup) {
    Object.keys((formGroup.controls.lane as FormGroup).controls).forEach( index => {
      Object.keys((formGroup.controls.lane as FormGroup).controls[index]['controls']).forEach(field => {
        const control = (formGroup.controls.lane as FormGroup).controls[index].get(field)
        if (control instanceof FormControl) {
          control.markAsTouched({ onlySelf: true });
        } else if (control instanceof FormGroup) {
          this.validateAllFormFields(control);
        }
      });
    });
  }

  

  hasPermission(checkPerm: string) {
    return Object.keys(this.ngxPermissionServ.getPermissions()).find(x => x == checkPerm) != undefined;
  }

  hasAnyPermission(permName: string) {
    return Object.keys(this.ngxPermissionServ.getPermissions()).find(x => x.includes(permName)) != undefined;
  }

  setSearchFilter(searchForm: FormGroup) {
    var searchData = new FormGroup({});
    Object.keys(searchForm.controls).forEach(key => {
      if (searchForm.controls[key].value != null) {
        if (searchForm.controls[key].value != "null" && (searchForm.controls[key].value.length > 0 || typeof searchForm.controls[key].value === 'number' || (searchForm.controls[key].value instanceof Date) || typeof searchForm.controls[key].value === 'boolean')) {
          searchData.addControl(key, searchForm.get(key));
        }
      }
    });
    return searchData;
  }

  getTicketStatus(status: string) {
    return this.ticketStatusDesc[status];
  }

  getCarStatus(key: string) {
    return (this.carStatus[key])?this.carStatus[key]:key;
  }

  getPayChannel(key: string) {
    return this.payChannel[key];
  }

  getLocationById(key: string) {
    return this.initData.location.find(x=>x.location_id==key);
  }

  getAccuseByCode(key: string) {
    return this.initData.accuse.find(x=>x.code==key);
  }

  getProvince(key: string) {
    return this.initData.province.find(x=>x.prov_lic_code==key);
  }

  getProvinceKhet(key: string) {
    return this.initData.province.find(x=>x.prov_code==key);
  }

  getAmphur(key: string, provLic: string) {
    return this.initData.amphurTransport.find(x=>x.amp_code==key && x.prov_lic_code==provLic);
  }

  getTambon(key: string) {
    return this.initData.tambon.find(x=>x.tam_code==key);
  }

  getTicketTransportStatus(key: string) {
    return this.initData.ticketTransportStatus.find(x=>x.code==key);
  }


  getFilterLocationOwnPoliceStation(locations: Location[], policeStationId: number) {
    return locations.filter(x => x.police_station_id == policeStationId).map(
      (loc: Location) => {
        return { value: loc.id, label: loc.location_shortname };
      });
  }

  getLevelPermission(permissionName: string) {
    var perm = permissionName.split("_");
    if (perm != undefined) {
      return perm[1];
    } else {
      return null;
    }
  }

  getLocationPermission(permissionName: string) {
    if (this.hasPermission(permissionName)) {
      //get level
      var level = this.getLevelPermission(permissionName);
      if (level != null) {
        if (level == '1' || level == '2') {
          return this.initData.location.filter(x => x.police_station_id == this.userServ.curtrentUser.police_station_id && x.is_active).map(
            (loc: Location) => {
              return { value: loc.location_id, label: loc.location_shortname };
            }
          )
        } else if (level == '3') {
          return this.initData.location.filter(x => x.is_active).map(
            (loc: Location) => {
              return { value: loc.location_id, label: loc.location_shortname };
            }
          )
        }
      } else {
        return [];
      }
    }
  }

  getCameraPermission(permissionName: string) {
    if (this.hasPermission(permissionName)) {
      //get level
      var level = this.getLevelPermission(permissionName);
      if (level != null) {
        if (level == '1') {
          return this.initData.camera.filter(x => x.police_station_id == this.userServ.curtrentUser.police_station_id && x.is_active).map(
            (cam: CameraInfo) => {
              return { value: cam.id, label: cam.camera_name };
            }
          )
        } else if (level == '3') {
          return this.initData.camera.filter(x => x.is_active).map(
            (cam: CameraInfo) => {
              return { value: cam.id, label: cam.camera_name };
            }
          )
        }
      } else {
        return [];
      }
    }
  }

  getCameraPermissionNumber(permissionName: string) {
    if (this.hasPermission(permissionName)) {
      //get level
      var level = this.getLevelPermission(permissionName);
      if (level != null) {
        if (level == '1') {
          return this.initData.camera.filter(x => x.police_station_id == this.userServ.curtrentUser.police_station_id).map(
            (cam: CameraInfo) => {
              return { value: cam.camera_number, label: cam.camera_name };
            }
          )
        } else if (level == '3') {
          return this.initData.camera.map(
            (cam: CameraInfo) => {
              return { value: cam.camera_number, label: cam.camera_name };
            }
          )
        }
      } else {
        return [];
      }
    }
  }

  getPoliceStationPermission(permissionName: string) {
    if (this.hasPermission(permissionName)) {
      //get level
      var level = this.getLevelPermission(permissionName);
      if (level != null) {
        if (level == '1' || level == '2') {
          return this.initData.policeStation.filter(x => x.id == this.userServ.curtrentUser.police_station_id).map(
            (police: PoliceStation) => {
              return { value: police.id, label: police.thai_short_name };
            }
          )
        } else if (level == '3') {
          return this.initData.policeStation.map(
            (police: PoliceStation) => {
              return { value: police.id, label: police.thai_short_name };
            }
          )
        }
      } else {
        return [];
      }
    }
  }

  getCameraHcm(res : any) {
    return res.map(
      (item: any) => {
        return { value: item.indexCode_laneDirection, label: item.name+" "+item.laneDirection, laneDirection: item.laneDirection, name: item.name, indexCode : item.indexCode };
      }
    )
  }

  getCameraConfig(res : any) {
    return res.map(
      (item: any) => {
        return { value: item.id, label: item.name };
      }
    )
  }

  getImageRes(res : any) {
    return res.map(
      (item: any) => {
        return { value: item.id, label: item.name };
      }
    )
  }

  getTitleName(titleType:number){
    return this.initData.title.find(x=>x.id==titleType).title;
  }

  getReasonById(key: string) {
    return this.initData.reason.find(x=>x.reason_type==key).reason_desc;
    
  }

}