import { Component, OnInit } from '@angular/core';
import { Observable } from 'rxjs';
import { MDBModalRef } from 'ng-uikit-pro-standard';

@Component({
  selector: 'app-print-share-dialog',
  templateUrl: './print-share-dialog.component.html',
  styleUrls: ['./print-share-dialog.component.scss']
})
export class PrintShareDialogComponent implements OnInit {
  data: number[] = [];
  isLoading: boolean = true;
  isError: boolean;
  msgError: string;
  linkDownload: string;
  titlename: string;
  printObsv: Observable<any>;

  constructor(public modalRef: MDBModalRef, ) { }

  ngOnInit() {
    this.printObsv.subscribe(
      (res: any) => {
        //console.log("res");
        this.isError = false;
        this.isLoading = false;
        this.linkDownload = res['url'];
      },(err) =>{
        console.log(err)
        this.isError = true;
        this.isLoading = false;
        this.msgError = err.error.error
        this.modalRef.hide()
        return
      }
    );
  }

}
